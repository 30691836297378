import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { faTrashAlt, faCheck, faTimes, faPencilAlt } from
  '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { fetchCurrentUnit, setCurrentUnit } from '../app/actions';
import * as server from '../serverAPI.js';
import ErrorAlert from '../util/ErrorAlert';
import UnitNav from './UnitNav';


class UnitSemesterList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      semesters: null,
      error: null,
    };
  }
  
  async componentDidMount() {
    const unitId = parseInt(this.props.match.params.unitId);
    if (isNaN(unitId)) {
      this.setState({ error: "unit id is not an integer" });
      return;
    }
    try {
      if (!this.props.currentUnit || this.props.currentUnit.id !== unitId) {
        const unit = await server.getUnit(unitId);
        this.props.setCurrentUnit(unit);
      }
      const semesters = await server.getUnitSemesters(unitId);
      this.setState({ semesters });
    } catch (error) {
      this.setState({ error: error.message });
    }
    document.title = "Override Requests - Semesters";
  }
  
  async removeSemester(semesterId) {
    this.setState({ error: null, success: null });
    try {
      await server.removeSemester(semesterId);
      const semesters = await server.getUnitSemesters(this.props.match.params.unitId);
      this.setState({ semesters });
      if (this.props.currentUnit) {
        // semesters are also loaded with the unit, it has to be reloaded
        await this.props.fetchCurrentUnit(this.props.currentUnit.id);
      }
    } catch (error) {
      this.setState({ error: "Remove semester: " + error.message });
    }
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        {this.props.currentRole === 'superuser' &&
          <>
            <LinkContainer to="/units">
              <Breadcrumb.Item>Units</Breadcrumb.Item>
            </LinkContainer>
            <LinkContainer to={'/units/' + this.props.match.params.unitId}>
              <Breadcrumb.Item>Unit</Breadcrumb.Item>
            </LinkContainer>
          </>
        }
        <Breadcrumb.Item active>Semesters</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  semesterList() {
    if (this.state.semesters == null)
      return null;
    const yesNoIcon = (b) =>
      (b ?
        <FontAwesomeIcon icon={faCheck} title="Yes"/>
        : <FontAwesomeIcon icon={faTimes} title="No"/>
      );
    return (
      <div className="table-responsive">
        <Table bordered size="sm" className="data table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th>Title</th>
              <th>Active</th>
              <th>Current</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.semesters.map(semester => {
              const semesterLink = '/units/'+this.props.match.params.unitId +
                '/semesters/' + semester.id;
              return (
                <tr key={semester.id}>
                  <td className="code"><Link to={semesterLink}>{semester.title}</Link></td>
                  <td>{yesNoIcon(semester.active)}</td>
                  <td>{yesNoIcon(semester.current)}</td>
                  <td>
                    <LinkContainer to={semesterLink}>
                      <Button title="Edit" size="xs">
                        <FontAwesomeIcon icon={faPencilAlt} title="Edit" />
                      </Button>
                    </LinkContainer>
                    <Button title="Remove" variant="danger" size="xs"
                      onClick={(e) => this.removeSemester(semester.id)}>
                      <FontAwesomeIcon icon={faTrashAlt} title="Remove" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
  
  render() {
    const unitId = parseInt(this.props.match.params.unitId);
    return (
      <>
        {this.breadcrumbs()}
        <UnitNav/>
        <h1>Semesters</h1>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        <LinkContainer to={`/units/${unitId}/semesters/create`}>
          <Button>Create a new semester</Button>
        </LinkContainer>
        { this.semesterList() }
      </>
    );
  }
  
}

UnitSemesterList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string.isRequired,
    })
  }),
  // from redux connect:
  currentUnit: PropTypes.object,
  currentRole: PropTypes.string.isRequired,
  fetchCurrentUnit: PropTypes.func.isRequired,
  setCurrentUnit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUnit, currentRole } = state.app;
  return { currentUnit, currentRole };
};
const mapDispatchToProps = { fetchCurrentUnit, setCurrentUnit };

export default connect(mapStateToProps, mapDispatchToProps)(UnitSemesterList);
