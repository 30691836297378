import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Table from 'react-bootstrap/Table';

import { Link } from 'react-router-dom';

import * as server from '../serverAPI.js';
import ErrorAlert from '../util/ErrorAlert';


class UpdateList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      updates: this.props.request ? this.props.request.updates : null,
      error: null,
    };
  }
  
  async componentDidMount() {
    if (this.state.updates)
      return;
    try {
      const updates = await server.getRequestUpdates(
        this.props.match.params.requestId);
      this.setState({ updates });
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.request && this.props.request !== prevProps.request)
      this.setState({ updates: this.props.request.updates });
  }
  
  updateList() {
    if (this.state.updates == null)
      return null;
    if (this.state.updates.length === 0)
      return <p>No update.</p>;
    return (
      <div className="table-responsive">
        <Table bordered size="sm" className="data">
          <thead>
            <tr>
              <th>User</th>
              <th>Date</th>
              <th>Changes</th>
            </tr>
          </thead>
          <tbody>
            {this.state.updates.map(update => {
              const changes = JSON.parse(update.changes);
              return (
                <tr key={update.id}>
                  <td>
                    {this.props.currentRole === 'superuser' ?
                      <Link to={'/users/'+update.userId}>
                        {update.user.firstname + ' ' + update.user.lastname}
                      </Link>
                      :
                      update.user.firstname + ' ' + update.user.lastname
                    }
                  </td>
                  <td>{(new Date(update.date)).toLocaleDateString()}</td>
                  <td>
                    <Table bordered size="sm" className="data">
                      <thead>
                        <tr>
                          <th>Field</th>
                          <th>Old Value</th>
                          <th>New Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(changes).map(f => {
                          let oldValue = changes[f].old;
                          let newValue = changes[f].new;
                          if (f.startsWith('date')) {
                            oldValue = oldValue == null ? '' :
                              (new Date(oldValue)).toLocaleString();
                            newValue = newValue == null ? '' :
                              (new Date(newValue)).toLocaleString();
                          }
                          return (
                            <tr key={f}>
                              <td>{f}</td>
                              <td>{oldValue == null ? '' : String(oldValue)}</td>
                              <td>{newValue == null ? '' : String(newValue)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
  
  render() {
    return (
      <>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        { this.updateList() }
      </>
    );
  }
  
}

UpdateList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string.isRequired,
    })
  }),
  request: PropTypes.object,
  // from redux connect:
  currentRole: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { currentRole } = state.app;
  return { currentRole };
};

export default connect(mapStateToProps)(UpdateList);
