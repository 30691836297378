import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import * as server from '../serverAPI.js';
import { fetchUnits } from '../units/actions';
import { setUsers } from '../users/actions';
import ErrorAlert from '../util/ErrorAlert';
import SuccessAlert from '../util/SuccessAlert';


class User extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      user: {
        id: this.props.match.params.userId,
        msuId: '',
        firstname: '',
        lastname: '',
        superuser: false,
        pid: '',
        email: '',
        phone: '',
        lastLogin: null,
      },
      error: null,
      success: null,
      unitsToAdd: null,
      selectedUnit: null,
      role: 'admin',
      roles: null,
      loaded: false,
    };
  }
  
  async componentDidMount() {
    const id = this.state.user.id;
    try {
      if (id != null) {
        const user = await server.getUser(id);
        for (const f in user) {
          if (user[f] == null)
            user[f] = '';
        }
        this.setState({ user, loaded: true });
      }
      const roles = await server.getRoles();
      this.setState({ roles });
      if (this.props.units == null)
        await this.props.fetchUnits();
      if (this.state.user.units != null)
        this.updateUnitsToAdd();
    } catch (error) {
      this.setState({ error: error.message });
    }
    if (id == null)
      document.title = "Override Requests - New User";
    else
      document.title = "Override Requests - " + (this.state.user.msuId ?
        "User: " + this.state.user.msuId: "User");
    if (this.state.user.msuId === '') {
      const msuIdInput = document.getElementById('msuId');
      if (msuIdInput != null)
        msuIdInput.focus();
    }
  }
  
  updateUnitsToAdd() {
    const unitsToAdd = this.props.units
      .filter((u) => !this.state.user.units.some((u2) => u2.id === u.id));
    this.setState({
      unitsToAdd,
      selectedUnit: unitsToAdd.length === 0 ? '' : unitsToAdd[0].id
    });
  }
  
  handleUserChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      user: {
        ...this.state.user,
        [target.name]: value
      }
    });
  }
  
  handleChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }
  
  async removeUser() {
    this.setState({ error: null, success: null });
    this.props.setUsers(null);
    try {
      await server.removeUser(this.state.user.id);
      this.props.history.push('/users/');
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  async saveUser() {
    this.setState({ error: null, success: null });
    this.props.setUsers(null);
    try {
      if (this.state.user.id) {
        const user = { ...this.state.user };
        for (const f in user) {
          if (user[f] === '')
            user[f] = null;
        }
        await server.updateUser(user);
      } else {
        const user = await server.createUser(this.state.user);
        for (const f in user) {
          if (user[f] == null)
            user[f] = '';
        }
        this.setState({ user, loaded: true });
        this.props.history.replace('/users/' + this.state.user.id);
        this.updateUnitsToAdd();
      }
      this.setState({ success: "The user was successfully saved." });
      document.title = "Override Requests - " + (this.state.user.msuId ?
        "User: " + this.state.user.msuId: "User");
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  async removeUnit(unitId) {
    this.setState({ error: null, success: null });
    try {
      await server.removeUserUnit(this.state.user.id, unitId);
      const user = await server.getUser(this.state.user.id);
      this.setState({ user });
      this.updateUnitsToAdd();
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/users">
          <Breadcrumb.Item>Users</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>User</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  userUnitList() {
    if (this.state.user.units == null)
      return null;
    return this.state.user.units.map(unit => (
      <tr key={unit.id}>
        <td className="code"><Link to={'/units/'+unit.id}>{unit.name}</Link></td>
        <td>{unit.userUnit.role}</td>
        <td>
          <Button title="Remove" variant="danger" size="xs"
              onClick={(e) => this.removeUnit(unit.id)}>
            <FontAwesomeIcon icon={faTrashAlt} title="Remove" />
          </Button>
        </td>
      </tr>
    ));
  }
  
  async addUnit() {
    this.setState({ error: null, success: null });
    try {
      await server.addUserUnit(this.state.user.id,
        this.state.selectedUnit, this.state.role);
      const user = await server.getUser(this.state.user.id);
      this.setState({ user });
      this.updateUnitsToAdd();
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  alerts() {
    return (
      <>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        <SuccessAlert message={this.state.success}
          onClose={() => this.setState({ success: null })}/>
      </>
    );
  }
  
  userForm() {
    const user = this.state.user;
    if (user.id != null && !this.state.loaded)
      return null;
    return (
      <Form onSubmit={e => { e.preventDefault(); this.saveUser(); } }>
        <fieldset>
          <legend>User Information</legend>
          <Form.Group as={Row} controlId="msuId">
            <div className="col-md-4">
              <Form.Label>MSU Id</Form.Label>
              <Form.Control name="msuId" type="text" maxLength="30"
              value={user.msuId}
              required onChange={e => this.handleUserChange(e)}/>
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId="firstname">
            <div className="col-md-4">
              <Form.Label>First Name</Form.Label>
              <Form.Control name="firstname" type="text" maxLength="100"
              value={user.firstname}
              onChange={e => this.handleUserChange(e)}/>
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId="lastname">
            <div className="col-md-4">
              <Form.Label>Last Name</Form.Label>
              <Form.Control name="lastname" type="text" maxLength="100"
              value={user.lastname}
              onChange={e => this.handleUserChange(e)}/>
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId="superuser">
            <div className="form-check">
              <Form.Check name="superuser" type="checkbox" custom
              value={user.superuser}
              onChange={e => this.handleUserChange(e)}
              checked={user.superuser}
              label="Superuser"/>
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId="pid">
            <div className="col-md-4">
              <Form.Label>PID</Form.Label>
              <Form.Control name="pid" type="text" maxLength="10"
              value={user.pid} pattern="[A-Z]\d+"
              onChange={e => this.handleUserChange(e)}/>
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId="email">
            <div className="col-md-4">
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" type="email" maxLength="100"
              value={user.email}
              onChange={e => this.handleUserChange(e)}/>
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId="phone">
            <div className="col-md-4">
              <Form.Label>Phone</Form.Label>
              <Form.Control name="phone" type="tel" maxLength="20"
              value={user.phone}
              onChange={e => this.handleUserChange(e)}/>
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId="classLevel">
            <div className="col-md-4">
              <Form.Label>Class Level</Form.Label>
              <Form.Control name="classLevel" as="select" custom
                value={user.classLevel}
                onChange={e => this.handleUserChange(e)}>
                <option value="">-- Select --</option>
                <option value="Freshman">Freshman</option>
                <option value="Sophomore">Sophomore</option>
                <option value="Junior">Junior</option>
                <option value="Senior">Senior</option>
                <option value="Lifelong Student">Lifelong Student</option>
                <option value="Graduate Student">Graduate Student</option>
              </Form.Control>
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId="primaryMajor">
            <div className="col-md-4">
              <Form.Label>Primary Major</Form.Label>
              <Form.Control name="primaryMajor" type="text" maxLength="100"
              value={user.primaryMajor}
              onChange={e => this.handleUserChange(e)}/>
            </div>
          </Form.Group>
          <div>
            <Button variant="primary" type="submit">
            Save
            </Button>
          </div>
        </fieldset>
      </Form>
    );
  }
  
  units() {
    const unitsHTML = this.userUnitList();
    if (unitsHTML == null)
      return null;
    return (
      <section className="border">
        <h2>Units</h2>
        <div className="table-responsive">
          <Table bordered size="sm" className="data table-hover table-striped table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {unitsHTML}
            </tbody>
          </Table>
        </div>
        {this.state.roles && this.state.unitsToAdd && this.state.unitsToAdd.length > 0 &&
          <Form onSubmit={e => { e.preventDefault(); this.addUnit(); } } inline>
            <Form.Group controlId="selectedUnit" className="mr-1">
              <Form.Label className="mr-1">Add a unit:</Form.Label>
              <Form.Control name="selectedUnit" as="select" custom
                  value={this.state.selectedUnit}
                  onChange={e => this.handleChange(e)} className="mr-1">
                {this.state.unitsToAdd
                  .map((g) =>
                    <option key={g.id} value={g.id}>{g.name}</option>
                  )}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="role">
              <Form.Label className="mr-1">Role:</Form.Label>
              <Form.Control name="role" as="select" custom
                  value={this.state.role}
                  onChange={e => this.handleChange(e)}>
                {this.state.roles.map((r, i) =>
                  <option key={i} value={r}>{r}</option>
                )}
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" className="ml-2">
              Add
            </Button>
          </Form>
        }
      </section>
    );
  }
  
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <h1>{this.state.user.id ? "User: " + this.state.user.msuId : "New User"}</h1>
        {this.alerts()}
        {this.state.loaded &&
        <Button variant="danger" size="sm" onClick={(e) => this.removeUser()}
            disabled={this.state.user.id === this.props.currentUser.id}>
          Remove user
        </Button>
        }
        {this.userForm()}
        {this.units()}
      </>
    );
  }
  
}

User.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string, // this initial user id can be undefined for a new user
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  // from redux connect:
  currentUser: PropTypes.object.isRequired,
  units: PropTypes.array,
  fetchUnits: PropTypes.func.isRequired,
  setUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.app;
  const { units } = state.units;
  return { currentUser, units };
};
const mapDispatchToProps = { fetchUnits, setUsers };

export default connect(mapStateToProps, mapDispatchToProps)(User);
