import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { faTrashAlt, faCheck, faTimes, faPencilAlt } from
  '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { setCurrentUnit } from '../app/actions';
import { fetchUnits } from '../units/actions';
import * as server from '../serverAPI.js';
import ErrorAlert from '../util/ErrorAlert';
import NavBar from '../util/NavBar';


class UnitList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }
  
  async componentDidMount() {
    if (this.props.units == null) {
      try {
        await this.props.fetchUnits();
      } catch (error) {
        this.setState({ error: error.message });
      }
    }
    document.title = "Override Requests - Units";
  }
  
  async removeUnit(unitId) {
    this.setState({ error: null, success: null });
    try {
      await server.removeUnit(unitId);
      if (this.props.currentUnit != null && this.props.currentUnit.id === unitId)
        this.props.setCurrentUnit(null);
      await this.props.fetchUnits();
    } catch (error) {
      this.setState({ error: "Remove unit: " + error.message });
    }
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Units</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  unitList() {
    const yesNoIcon = (b) =>
      (b ?
        <FontAwesomeIcon icon={faCheck} title="Yes"/>
        : <FontAwesomeIcon icon={faTimes} title="No"/>
      );
    if (this.props.units == null)
      return null;
    return this.props.units.map(unit => (
      <tr key={unit.id}>
        <td className="code"><Link to={'/units/'+unit.id}>{unit.name}</Link></td>
        <td>{yesNoIcon(unit.active)}</td>
        <td>
          <LinkContainer to={'/units/'+unit.id}>
            <Button title="Edit" size="xs">
              <FontAwesomeIcon icon={faPencilAlt} title="Edit" />
            </Button>
          </LinkContainer>
          <Button title="Remove" variant="danger" size="xs"
              onClick={(e) => this.removeUnit(unit.id)}
              disabled={unit.name === 'Guests' || unit.name === 'Superusers'}>
            <FontAwesomeIcon icon={faTrashAlt} title="Remove" />
          </Button>
        </td>
      </tr>
    ));
  }
  
  render() {
    const unitsHTML = this.unitList();
    return (
      <>
        {this.breadcrumbs()}
        <NavBar items={ [
          { title: "Users", path: '/users' },
          { title: "Units", path: '/units' },
          { title: "Sessions", path: '/users/sessions' },
        ] }/>
        <h1>Units</h1>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        <LinkContainer to="/units/create">
          <Button>Create a new unit</Button>
        </LinkContainer>
        {unitsHTML &&
        <div className="table-repsonsive">
          <Table bordered size="sm" className="data table-hover table-striped table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {unitsHTML}
            </tbody>
          </Table>
        </div>
        }
      </>
    );
  }
  
}

UnitList.propTypes = {
  // from redux connect:
  currentUnit: PropTypes.object,
  units: PropTypes.array,
  setCurrentUnit: PropTypes.func.isRequired,
  fetchUnits: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUnit } = state.app;
  const { units } = state.units;
  return { currentUnit, units };
};
const mapDispatchToProps = { setCurrentUnit, fetchUnits };

export default connect(mapStateToProps, mapDispatchToProps)(UnitList);
