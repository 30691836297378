import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { faArchive, faBan, faEnvelope, faEye, faPencilAlt, faTrashAlt, faUndo } from
  '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LinkContainer } from 'react-router-bootstrap';

import SortedTable from '../util/SortedTable';


class RequestList extends Component {
  
  constructor(props) {
    super(props);
    this.columnTitles = {
      admin: "Admin",
      adminNotes: "Notes",
      approvedSections: "Approved Sec.",
      classLevel: "Level",
      comments: "Comments",
      completePrereq: "Comp. Prereq.",
      course: "Course",
      courseSubject: "Subject",
      courseCode: "Code",
      credits: "Credits",
      dateConfirmationSent: "Confirmation",
      dateEnrolled: "Enrolled",
      dateEntered: "Entered",
      dateSubmitted: "Submitted",
      email: "Email",
      expectedGraduation: "Graduation",
      firstname: "First Name",
      lastname: "Last Name",
      major: "Major",
      otherReason: "Other Reason",
      phone: "Phone",
      pid: "PID",
      prereqNeeded: "Prereq. Needed",
      prereqTransfer: "Prereq. Transfer",
      reason: "Reason",
      requestReason: "Reason",
      sections: "Sections",
      status: "Status",
      transferPrereq: "Trans. Prereq.",
    };
  }
  
  adminFieldValue(request) {
    let value;
    const updates = request.updates;
    if (updates && updates.length > 0) {
      const lastUpdate = updates[updates.length - 1];
      const user = lastUpdate.user;
      if (user)
        value = user.msuId;
      else
        value = '?';
    } else {
      value = '';
    }
    return value;
  }
  
  fieldValue(request, columnName) {
    let value;
    if (columnName === 'admin')
      value = this.adminFieldValue(request);
    else if (columnName === 'course')
      value = request.courseSubject + ' - ' + request.courseCode + ' - ' +
        request.sections.replace(/,\s*/g, ', ');
    else if (columnName.startsWith('date'))
      value = (new Date(request[columnName])).toLocaleDateString();
    else if (columnName === 'reason')
      value = request.requestReason === 'Other' ? request.otherReason : request.requestReason;
    else
      value = request[columnName];
    return value;
  }
  
  requestTableRow(request, columns) {
    const role = this.props.currentRole;
    const admin = role === 'superuser' || role === 'admin';
    const trClass = (request.updates &&
      request.updates.length === 0) ? 'new' :
      (request.status === 'Cancelled' ? 'cancelled' : '');
    return (
      <tr key={request.id} className={trClass}>
        {columns.map(f => {
          const value = this.fieldValue(request, f);
          return <td key={request.id + '_' + f}>{value}</td>;
        })}
        <td>
          {!request.archived && admin &&
            <>
              <LinkContainer to={'/requests/'+request.id}>
                <Button title="Edit" size="xs">
                  <FontAwesomeIcon icon={faPencilAlt} title="Edit" />
                </Button>
              </LinkContainer>
              <LinkContainer to={'/requests/'+request.id+'/messages/create'}>
                <Button title="Email" variant="info" size="xs" disabled={!admin}>
                  <FontAwesomeIcon icon={faEnvelope} title="Email" />
                </Button>
              </LinkContainer>
              <Button title="Archive" variant="warning" size="xs"
                  onClick={(e) => this.props.archiveRequest(request.id)}
                  disabled={!admin}>
                <FontAwesomeIcon icon={faArchive} title="Archive" />
              </Button>
              <Button title="Remove" variant="danger" size="xs"
                  onClick={(e) => this.props.removeRequest(request.id)}
                  disabled={!admin}>
                <FontAwesomeIcon icon={faTrashAlt} title="Remove" />
              </Button>
            </>
          }
          {(request.archived || !admin) &&
            <LinkContainer to={'/requests/'+request.id}>
              <Button title="View" size="xs">
                <FontAwesomeIcon icon={faEye} title="View" />
              </Button>
            </LinkContainer>
          }
          {request.archived && admin &&
            <Button title="Restore" variant="warning" size="xs"
                onClick={(e) => this.props.restoreRequest(request.id)}
                disabled={!admin}>
              <FontAwesomeIcon icon={faUndo} title="Restore" />
            </Button>
          }
          {!admin && !request.archived && request.status === 'Submitted' &&
            <Button title="Cancel" variant="danger" size="xs"
                onClick={(e) => this.props.cancelRequest(request.id)}>
              <FontAwesomeIcon icon={faBan} title="Cancel" />
            </Button>
          }
        </td>
      </tr>
    );
  }
  
  requestTypeSelect() {
    if (this.props.currentUnit.semesters == null ||
        this.props.currentUnit.semesters.length === 0)
      return null;
    return (
      <Form inline>
        <Form.Group controlId="requestType">
          <Form.Label className="mr-1">Request type:</Form.Label>
          <Form.Control name="requestType" as="select" custom
              value={this.props.requestType}
              onChange={e => this.handleChange(e)}>
            <option value="current">Current</option>
            <option value="archived">Archived</option>
          </Form.Control>
        </Form.Group>
      </Form>
    );
  }
  
  semesterSelect(admin) {
    if (this.props.currentUnit.semesters == null ||
        this.props.currentUnit.semesters.length === 0)
      return <p>No semester defined for this unit yet.</p>;
    const semesters = this.props.currentUnit.semesters.filter(
      s => (s.current || admin) && s.active);
    return (
      <Form inline>
        <Form.Group controlId="semesterId">
          <Form.Label className="mr-1">Semester:</Form.Label>
          <Form.Control name="semesterId" as="select" custom
              value={this.props.semesterId}
              onChange={e => this.handleChange(e)}>
            <option key="0" value="">--Select a semester--</option>
            {semesters.map((s) =>
              <option key={s.id} value={s.id}>{s.title}</option>
            )}
          </Form.Control>
        </Form.Group>
      </Form>
    );
  }
  
  downloadButton() {
    if (this.props.currentUnit.semesters == null ||
        this.props.currentUnit.semesters.length === 0)
      return null;
    if (this.props.requests == null || this.props.requests.length === 0)
      return null;
    // based on RFC 4180
    const quote = (v) => v == null ? '' : '"' + v.replace(/"/g, '""') + '"';
    let text = '';
    const columns = this.props.currentUnit.settings.requestListTableColumns.split(',');
    for (let i=0; i<columns.length; i++) {
      text += quote(this.columnTitles[columns[i]]);
      if (i < columns.length - 1)
        text += ',';
    }
    text += '\n';
    if (this.props.requests != null) {
      for (const r of this.props.requests) {
        for (let i=0; i<columns.length; i++) {
          const f = columns[i];
          text += quote(this.fieldValue(r, f));
          if (i < columns.length - 1)
            text += ',';
        }
        text += '\n';
      }
    }
    return (
      <Form inline>
        <Form.Group>
          <Form.Label srOnly className="mr-1">Action:</Form.Label>
          <Button variant="primary" download="requests.csv"
              href={'data:text/plain;charset=utf-8,' + encodeURIComponent(text)}>
            Download CSV
          </Button>
        </Form.Group>
      </Form>
    );
  }
  
  handleChange(event) {
    const target = event.target;
    this.props.changeSelection(target.name, target.value);
  }
  
  requestSection(admin) {
    if (this.props.requests == null)
      return null;
    if (this.props.requests.length === 0)
      return <p className="mt-3">No request matching above criteria.</p>;
    let columns = (this.props.requestType === 'archived' ?
      this.props.currentUnit.settings.archiveListTableColumns :
      this.props.currentUnit.settings.requestListTableColumns)
      .split(',');
    if (!admin) {
      columns = columns.filter(c =>
        !['pid', 'firstname', 'lastname', 'admin', 'adminNotes', 'dateEntered',
          'dateEnrolled', 'dateConfirmationSent'].includes(c));
    }
    const fields = [];
    for (const c of columns)
      fields.push([c, this.columnTitles[c]]);
    fields.push(['actions', '']);
    return (
      <SortedTable
        fields={fields}
        records={this.props.requests}
        recordTableRow={(request) => this.requestTableRow(request, columns)}
        recordValueToSort={(record, field) => this.fieldValue(record, field)}
      />
    );
  }
  
  render() {
    const role = this.props.currentRole;
    const admin = role === 'superuser' || role === 'admin';
    return (
      <>
        {this.props.currentUnit.semesters &&
            this.props.currentUnit.semesters.length > 0 &&
          <p className="my-2">
            <LinkContainer to="/requests/create">
              <Button>Create a new request</Button>
            </LinkContainer>
          </p>
        }
        {admin ?
          <>
            <Container fluid className="request-list-top-forms"><Row>
              <Col xs="12" sm="auto">{ this.requestTypeSelect() }</Col>
              <Col xs="12" sm="auto">{ this.semesterSelect(admin) }</Col>
              <Col xs="12" sm="auto">{ this.downloadButton() }</Col>
            </Row></Container>
            { this.requestSection(admin) }
            {this.props.currentUnit.semesters != null &&
              this.props.currentUnit.semesters.filter(s => s.active).length > 1 &&
                <p>
                  Note: there may be requests for other semesters.
                </p>
            }
          </>
          :
          <section className="border">
            <h2>Past requests</h2>
            { this.semesterSelect(admin) }
            { this.requestSection(admin) }
          </section>
        }
      </>
    );
  }
  
}

RequestList.propTypes = {
  currentRole: PropTypes.string,
  currentUnit: PropTypes.object.isRequired,
  semesterId: PropTypes.string,
  requestType: PropTypes.string,
  changeSelection: PropTypes.func,
  requests: PropTypes.arrayOf(PropTypes.object),
  archiveRequest: PropTypes.func,
  restoreRequest: PropTypes.func,
  removeRequest: PropTypes.func,
  cancelRequest: PropTypes.func,
};

export default RequestList;
