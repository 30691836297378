import React from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { LinkContainer } from 'react-router-bootstrap';

function Accessibility(props) {
  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <h1>Accessibility</h1>
      <p>This application follows the <a href="http://www.w3.org/TR/WCAG20/">WCAG 2.0</a> recommendation, as per the <a href="https://webaccess.msu.edu/">MSU accessibility standards</a>.</p>
      <p>Please <a href="mailto:natsci.ticket@msu.edu">contact us</a> if you encounter any accessibility issue.</p>
    </>
  );
}

export default Accessibility;
