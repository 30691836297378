import React, { Component } from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinkContainer } from 'react-router-bootstrap';

import RequestListContainer from './RequestListContainer';


class RequestListPage extends Component {
  
  componentDidMount() {
    document.title = "Override Requests - Request List";
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Requests</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <h1>Requests</h1>
        <RequestListContainer/>
      </>
    );
  }
  
}

export default RequestListPage;
