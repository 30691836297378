
const protocol = window.location.protocol;
let port;
if (process.env.REACT_APP_NODE_ENV === 'production')
  port = process.env.REACT_APP_PRODUCTION_PORT;
else
  port = process.env.REACT_APP_DEVELOPMENT_API_PORT;
const portString = (protocol === 'https:' || port === '80') ? '' : ':' + port;
export const hostURL = protocol + '//' + window.location.hostname + portString;

const serverRequest = (method, path, parameters) => {
  return new Promise((resolve, reject) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 10000);
    const fetchParams = {
      method: method,
      signal: controller.signal
    };
    if (parameters != null) {
      fetchParams.headers = { 'Content-Type': 'application/json' };
      fetchParams.body = JSON.stringify(parameters);
    }
    fetch(path, fetchParams)
      .then((response) => {
        if (!response.ok)
          reject(new Error(response.statusText));
        return response;
      })
      .then(data => data.json())
      .then((res) => {
        if (!res.success)
          reject(new Error(res.error));
        else
          resolve(res.data);
      })
      .catch(error => {
        if (error.name === 'AbortError')
          reject(new Error("Timeout when connecting to server (" +
            error.message + ")"));
        else
          reject(new Error("Error retrieving data: " + error.message));
      })
      .finally(() => clearTimeout(timeout));
  });
};

// App
export const samlLogin = () => {
  window.location.href = hostURL + '/api/app/login/saml?RelayState=' +
    encodeURIComponent(window.location.href);
};
export const logout = async () => {
  await serverRequest('POST', '/api/app/logout');
};
export const impersonate = (userId) => {
  return serverRequest('POST', `/api/app/impersonate/${userId}`);
};
export const sessions = () => {
  return serverRequest('GET', `/api/app/sessions`);
};

// Users
export const getUsers = () => {
  return serverRequest('GET', '/api/users/');
};
export const createUser = (user) => {
  return serverRequest('POST', `/api/users/`, user);
};
export const getUser = (userId) => {
  return serverRequest('GET', `/api/users/${userId}`);
};
export const removeUser = (userId) => {
  return serverRequest('DELETE', `/api/users/${userId}`);
};
export const updateUser = (user) => {
  return serverRequest('PUT', `/api/users/${user.id}`, user);
};
export const addUserUnit = (userId, unitId, role) => {
  return serverRequest('POST', `/api/users/${userId}/add_unit`, { unitId, role });
};
export const removeUserUnit = (userId, unitId) => {
  return serverRequest('DELETE', `/api/users/${userId}/units/${unitId}`);
};
export const getCurrentUser = () => {
  return serverRequest('GET', '/api/users/current');
};

// Units
export const getUnits = () => {
  return serverRequest('GET', '/api/units/');
};
export const createUnit = (unit) => {
  return serverRequest('POST', `/api/units/`, unit);
};
export const getUnit = (unitId) => {
  return serverRequest('GET', `/api/units/${unitId}`);
};
export const removeUnit = (unitId) => {
  return serverRequest('DELETE', `/api/units/${unitId}`);
};
export const updateUnit = (unit) => {
  return serverRequest('PUT', `/api/units/${unit.id}`, unit);
};
export const addUnitUser = (unitId, userId, role) => {
  return serverRequest('POST', `/api/units/${unitId}/add_user`, { userId, role });
};
export const removeUnitUser = (unitId, userId) => {
  return serverRequest('DELETE', `/api/units/${unitId}/users/${userId}`);
};
export const getUnitSemesters = (unitId) => {
  return serverRequest('GET', `/api/units/${unitId}/semesters`);
};
export const getUnitStatistics = (unitId) => {
  return serverRequest('GET', `/api/units/${unitId}/statistics`);
};

// User-Unit roles
export const getRoles = () => {
  const roles = [
    'admin', 'csEntry'
  ];
  return Promise.resolve(roles);
};

// Semesters
export const createSemester = (semester) => {
  return serverRequest('POST', `/api/semesters/`, semester);
};
export const getSemester = (semesterId) => {
  return serverRequest('GET', `/api/semesters/${semesterId}`);
};
export const updateSemester = (semester) => {
  return serverRequest('PUT', `/api/semesters/${semester.id}`, semester);
};
export const removeSemester = (semesterId) => {
  return serverRequest('DELETE', `/api/semesters/${semesterId}`);
};
export const getSemesterRequests = (semesterId, requestType) => {
  return serverRequest('GET', `/api/semesters/${semesterId}/requests/${requestType}`);
};

// Settings
export const updateSettings = (settings) => {
  return serverRequest('PUT', `/api/settings/${settings.id}`, settings);
};

// Requests
export const createRequest = (request) => {
  return serverRequest('POST', `/api/requests/`, request);
};
export const getRequest = (requestId) => {
  return serverRequest('GET', `/api/requests/${requestId}`);
};
export const removeRequest = (requestId) => {
  return serverRequest('DELETE', `/api/requests/${requestId}`);
};
export const updateRequest = (request) => {
  return serverRequest('PUT', `/api/requests/${request.id}`, request);
};
export const cancelRequest = (requestId) => {
  return serverRequest('POST', `/api/requests/${requestId}/cancel`);
};
export const getRequestMessages = (requestId) => {
  return serverRequest('GET', `/api/requests/${requestId}/messages`);
};
export const sendMessage = (message) => {
  return serverRequest('POST', `/api/requests/${message.requestId}/messages/send`, message);
};
export const getRequestUpdates = (requestId) => {
  return serverRequest('GET', `/api/requests/${requestId}/updates`);
};

// Messages
export const getMessage = (messageId) => {
  return serverRequest('GET', `/api/messages/${messageId}`);
};
