import React, { Component } from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { LinkContainer } from 'react-router-bootstrap';

import * as server from '../serverAPI.js';
import ErrorAlert from '../util/ErrorAlert';
import NavBar from '../util/NavBar';
import SortedTable from '../util/SortedTable';


class SessionList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      users: null,
    };
  }
  
  async componentDidMount() {
    try {
      const users = await server.sessions();
      this.setState({ users });
    } catch (error) {
      this.setState({ error: error.message });
    }
    document.title = "Override Requests - Sessions";
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Sessions</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  sessionList() {
    if (this.state.users == null)
      return null;
    return (
      <div className="table-responsive">
        <SortedTable
        fields={[
          ['msuId', 'MSU Id'],
          ['firstname', 'First name'],
          ['lastname', 'Last name'],
          ['lastLogin', 'Last login'],
          ['lastActivity', 'Last activity'],
        ]}
        records={this.state.users}
        recordTableRow={(user) =>
          <tr key={user.id}>
            <td>{user.msuId}</td>
            <td>{user.firstname}</td>
            <td>{user.lastname}</td>
            <td>{user.lastLogin == null ? '' :
              (new Date(user.lastLogin)).toLocaleString()}</td>
            <td>{user.lastActivity == null ? '' :
              (new Date(user.lastActivity)).toLocaleString()}</td>
          </tr>
        }
        />
      </div>
    );
  }
  
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <NavBar items={ [
          { title: "Users", path: '/users' },
          { title: "Units", path: '/units' },
          { title: "Sessions", path: '/users/sessions' },
        ] }/>
        <h1>Active User Sessions</h1>
        <section>
          <ErrorAlert message={this.state.error}
            onClose={() => this.setState({ error: null })}/>
          { this.sessionList() }
        </section>
      </>
    );
  }
  
}

export default SessionList;
