import * as server from '../serverAPI.js';

// Redux action creators

export const setCurrentUser = (user) => ({
  type: 'SET_CURRENT_USER',
  user,
});

export const setCurrentUnit = (unit) => ({
  type: 'SET_CURRENT_UNIT',
  unit,
});

export const fetchCurrentUnit = (unitId) => {
  return async dispatch => {
    if (unitId == null) {
      dispatch(setCurrentUnit(null));
      return;
    }
    try {
      const unit = await server.getUnit(unitId);
      dispatch(setCurrentUnit(unit));
    } catch (error) {
      dispatch(setCurrentUnit(null));
      throw error;
    }
  };
};

export const fetchCurrentUser = () => {
  return async dispatch => {
    try {
      const user = await server.getCurrentUser();
      if (user != null)
        dispatch(setCurrentUser(user));
      else
        server.samlLogin();
    } catch (error) {
      dispatch(setCurrentUser(null));
      throw error;
    }
  };
};

export const resetAppState = () => {
  return {
    type: 'RESET_APP_STATE',
  };
};
