import React from 'react';

import { Link } from 'react-router-dom';

function Footer(props) {
  return (
    <footer>
      <div className="footer-container">
        <div id="footer-wordmark">
          <a href="https://msu.edu/">
            <svg id="icon-wordmark" viewBox="0 0 149 32">
              <title>MSU wordmark</title>
              <path d="M2.309 22.554v5.753c0 0.961 0.313 2.321 2.006 2.321 1.55 0 2.11-1.072 2.11-2.43l-0.026-5.644c0-0.316-0.157-0.509-0.352-0.62h1.511c-0.196 0.111-0.339 0.304-0.339 0.62l-0.039 5.616c0 2.334-1.316 3.24-3.087 3.24-1.524 0-3.465-0.714-3.465-3.144v-5.712c0-0.316-0.209-0.509-0.417-0.62h2.501c-0.208 0.111-0.404 0.304-0.404 0.62v0z"></path>
              <path d="M24.323 22.554c0-0.316-0.13-0.509-0.339-0.62h1.446c-0.196 0.111-0.339 0.304-0.339 0.62v9.104c0 0-6.252-7.771-6.252-7.882v6.879c0 0.302 0.143 0.495 0.364 0.618h-1.446c0.195-0.123 0.339-0.316 0.339-0.618v-7.332c0-0.536 0.013-0.55-0.234-0.866-0.131-0.165-0.313-0.357-0.587-0.523h2.241l4.807 6.043v-5.423z"></path>
              <path d="M38.157 21.935c-0.222 0.111-0.339 0.304-0.339 0.62v8.101c0 0.302 0.117 0.495 0.339 0.618h-2.358c0.208-0.123 0.339-0.316 0.339-0.618v-8.101c0-0.316-0.13-0.509-0.339-0.62h2.358z"></path>
              <path d="M54.036 22.622c0.118-0.357 0.065-0.55-0.273-0.688h1.589c-0.339 0.235-0.365 0.331-0.495 0.66-0.222 0.535-3.361 8.967-3.361 8.967s-3.4-8.46-3.647-8.967c-0.156-0.275-0.313-0.495-0.704-0.66h2.879c-0.339 0.138-0.365 0.427-0.274 0.757 0.026 0.123 2.202 5.767 2.202 5.767s2.071-5.74 2.084-5.836z"></path>
              <path d="M69.85 23.144c-0.247-0.358-0.521-0.467-1.016-0.495-0.39-0.027-2.214-0.027-2.214-0.027v3.159h1.967c0.182 0 0.404-0.22 0.469-0.385v1.455c-0.065-0.192-0.286-0.399-0.469-0.399h-1.967v4.038c0.26 0.040 1.055 0.097 1.693 0.069 1.133-0.028 1.654 0 2.137-0.865l-0.274 1.579h-5.601c0.222-0.138 0.391-0.287 0.391-0.618v-8.101c0-0.316-0.182-0.509-0.391-0.619h5.237l0.039 1.209z"></path>
              <path d="M82.198 22.622v3.584c0 0 0.365 0.028 0.587 0.028 0.886 0 1.824-0.522 1.824-1.909 0-0.632-0.365-1.73-1.654-1.73-0.392 0-0.757 0.027-0.757 0.027zM84.127 26.536c0.886 0.096 1.276 0.728 1.471 1.031 0 0 1.564 2.608 2.019 3.158 0.247 0.329 0.834 0.549 0.834 0.549l-1.694 0.028c-0.495 0-0.924-0.316-1.159-0.646-0.586-0.865-1.159-1.868-1.629-2.636-0.339-0.55-0.638-1.14-1.199-1.14-0.156 0-0.573 0.028-0.573 0.028v3.748c0 0.302 0.144 0.494 0.365 0.618h-2.357c0.208-0.123 0.339-0.316 0.339-0.618v-8.101c0-0.316-0.131-0.509-0.339-0.62h2.814c2.696 0 3.308 1.388 3.308 2.308 0 1.482-1.276 2.224-2.2 2.293z"></path>
              <path d="M102.741 28.527c0 1.717-1.433 2.884-3.034 2.884-1.186 0-2.045-0.426-2.501-0.659l-0.065-2.321c0.246 0.976 1.028 2.266 2.383 2.266 1.29 0 1.681-0.975 1.681-1.647 0-1.319-1.133-1.497-2.319-2.171-1.199-0.7-1.72-1.441-1.72-2.485 0-1.867 1.655-2.609 2.84-2.609 0.443 0 1.302 0.097 1.967 0.509l0.065 2.032c-0.182-0.728-0.924-1.827-2.175-1.827-0.99 0-1.38 0.728-1.38 1.319 0 0.837 0.56 1.153 1.785 1.744 1.211 0.604 2.474 1.058 2.474 2.966z"></path>
              <path d="M115.272 21.935c-0.222 0.111-0.339 0.304-0.339 0.62v8.101c0 0.302 0.117 0.495 0.339 0.618h-2.357c0.208-0.123 0.339-0.316 0.339-0.618v-8.101c0-0.316-0.131-0.509-0.339-0.62h2.357z"></path>
              <path d="M129.419 22.622v8.033c0 0.302 0.144 0.494 0.339 0.618h-2.333c0.208-0.123 0.339-0.316 0.339-0.618v-8.033c-0.964 0-2.085 0.097-2.085 0.097-0.313 0-0.534 0.316-0.651 0.548v-1.332h7.139v1.332c-0.117-0.232-0.3-0.52-0.613-0.548 0 0-1.186-0.097-2.136-0.097z"></path>
              <path d="M148.632 21.935c-0.405 0.138-0.704 0.523-0.86 0.838l-2.084 4.175v3.707c0 0.302 0.246 0.495 0.455 0.618h-2.605c0.195-0.123 0.443-0.288 0.443-0.618v-3.652l-2.553-4.408c-0.183-0.33-0.326-0.467-0.677-0.66h2.943c-0.326 0.138-0.547 0.207-0.208 0.852l1.836 3.378 1.72-3.542c0.182-0.303 0.090-0.591-0.273-0.688h1.863z"></path>
              <path d="M10.331 0.401h3.058c-0.29 0.152-0.47 0.419-0.47 0.858v11.252c0 0.419 0.181 0.687 0.47 0.858h-3.239c0.29-0.172 0.47-0.439 0.47-0.858v-9.593c0-0.228 0.037-0.438 0.037-0.438l-0.163 0.438-4.342 10.718-4.379-10.413-0.126-0.514v9.802c0 0.419 0.199 0.687 0.506 0.858h-2.009c0.272-0.172 0.471-0.439 0.471-0.858v-11.252c0-0.362-0.29-0.706-0.579-0.858h3.184l3.619 8.753 3.492-8.753z"></path>
              <path d="M19.594 0.401c-0.308 0.152-0.47 0.419-0.47 0.858v11.252c0 0.419 0.163 0.687 0.47 0.858h-3.275c0.29-0.172 0.47-0.439 0.47-0.858v-11.252c0-0.438-0.181-0.706-0.47-0.858h3.275z"></path>
              <path d="M33.471 4.138c-1.104-2.651-3.221-2.918-4.089-2.918-3.094 0-4.849 2.384-4.849 5.454 0 2.613 1.248 5.836 4.976 5.836 0.851 0 2.895-0.305 4.089-2.918 0.036-0.076-0.163 2.746-0.163 2.746-1.502 0.897-3.112 1.22-4.596 1.22-3.565 0-6.731-2.575-6.731-6.637 0-3.337 2.352-6.732 6.948-6.732 1.484 0 2.841 0.305 4.252 0.992 0 0 0.199 3.032 0.163 2.956z"></path>
              <path d="M44.072 0.401h3.275c-0.307 0.152-0.506 0.419-0.506 0.858v11.252c0 0.419 0.199 0.687 0.506 0.858h-3.275c0.29-0.172 0.47-0.439 0.47-0.858v-5.836h-5.627v5.836c0 0.419 0.163 0.687 0.47 0.858h-3.275c0.29-0.172 0.47-0.439 0.47-0.858v-11.252c0-0.438-0.181-0.706-0.47-0.858h3.275c-0.308 0.152-0.47 0.419-0.47 0.858v4.482h5.627v-4.482c0-0.438-0.181-0.706-0.47-0.858z"></path>
              <path d="M53.534 0.401c-0.308 0.152-0.47 0.419-0.47 0.858v11.252c0 0.419 0.163 0.687 0.47 0.858h-3.275c0.29-0.172 0.47-0.439 0.47-0.858v-11.252c0-0.438-0.181-0.706-0.47-0.858h3.275z"></path>
              <path d="M67.302 1.183l0.127 3.032c-0.922-2.594-3.221-2.994-4.071-2.994-3.075 0-4.849 2.384-4.849 5.454 0 2.613 1.23 5.836 4.976 5.836 0.38 0 1.447-0.038 2.298-0.629v-3.318c0-0.438-0.181-0.706-0.47-0.877h3.239c-0.271 0.172-0.47 0.439-0.47 0.877v2.823c0 0.381 0.199 0.724 0.289 0.781-1.52 0.897-4.089 1.392-5.537 1.392-3.601 0-6.785-2.575-6.785-6.636 0-3.338 2.37-6.732 7.002-6.732 1.484 0 2.823 0.344 4.252 0.992z"></path>
              <path d="M76.529 8.792c-0.851-2.117-1.845-4.672-1.845-4.672s-1.231 3.242-1.81 4.672h3.655zM80.509 12.434c0.181 0.401 0.307 0.61 0.904 0.935h-3.871c0.47-0.267 0.56-0.534 0.434-0.935-0.055-0.191-0.525-1.354-1.032-2.65h-4.433c-0.542 1.296-0.977 2.422-1.013 2.631-0.163 0.458-0.072 0.725 0.417 0.954h-2.497c0.506-0.363 0.669-0.572 0.85-0.935 0.344-0.725 5.103-12.434 5.103-12.434s4.813 11.709 5.139 12.434v0z"></path>
              <path d="M91.654 1.258c0-0.438-0.181-0.706-0.47-0.858h2.008c-0.271 0.152-0.47 0.419-0.47 0.858v12.644c0 0-8.685-10.794-8.685-10.947v9.555c0 0.419 0.199 0.687 0.507 0.858h-2.009c0.272-0.171 0.47-0.438 0.47-0.858v-10.184c0-0.744 0.019-0.763-0.326-1.201-0.181-0.229-0.435-0.496-0.815-0.725h3.112l6.677 8.391v-7.533z"></path>
              <path d="M108.025 9.555c0 2.384-1.989 4.005-4.216 4.005-1.645 0-2.84-0.591-3.473-0.916l-0.090-3.223c0.344 1.354 1.429 3.147 3.311 3.147 1.79 0 2.334-1.354 2.334-2.288 0-1.831-1.574-2.079-3.221-3.014-1.665-0.972-2.388-2.002-2.388-3.451 0-2.594 2.298-3.623 3.944-3.623 0.615 0 1.811 0.134 2.733 0.706l0.090 2.822c-0.252-1.011-1.284-2.536-3.021-2.536-1.375 0-1.918 1.011-1.918 1.831 0 1.164 0.778 1.602 2.479 2.422 1.683 0.839 3.437 1.469 3.437 4.12z"></path>
              <path d="M115.246 1.354v11.156c0 0.419 0.199 0.687 0.471 0.858h-3.239c0.289-0.171 0.47-0.438 0.47-0.858v-11.156c-1.339 0-2.896 0.133-2.896 0.133-0.434 0-0.742 0.439-0.904 0.763v-1.85h9.916v1.85c-0.163-0.324-0.416-0.725-0.851-0.763 0 0-1.647-0.133-2.968-0.133z"></path>
              <path d="M124.508 8.792c-0.85-2.117-1.845-4.672-1.845-4.672s-1.23 3.242-1.809 4.672h3.655zM128.489 12.434c0.181 0.401 0.308 0.61 0.905 0.935h-3.871c0.47-0.267 0.56-0.534 0.434-0.935-0.055-0.191-0.525-1.354-1.032-2.65h-4.433c-0.543 1.296-0.977 2.422-1.013 2.631-0.163 0.458-0.072 0.725 0.417 0.954h-2.498c0.506-0.363 0.669-0.572 0.851-0.935 0.344-0.725 5.101-12.434 5.101-12.434s4.813 11.709 5.139 12.434v0z"></path>
              <path d="M134.118 1.354v11.156c0 0.419 0.199 0.687 0.47 0.858h-3.239c0.29-0.171 0.47-0.438 0.47-0.858v-11.156c-1.339 0-2.895 0.133-2.895 0.133-0.434 0-0.742 0.439-0.905 0.763v-1.85h9.915v1.85c-0.162-0.324-0.415-0.725-0.85-0.763 0 0-1.647-0.133-2.967-0.133z"></path>
              <path d="M147.215 2.079c-0.343-0.496-0.723-0.648-1.411-0.687-0.542-0.037-3.075-0.037-3.075-0.037v4.386h2.732c0.253 0 0.56-0.305 0.651-0.534v2.022c-0.091-0.267-0.399-0.553-0.651-0.553h-2.732v5.606c0.362 0.057 1.465 0.134 2.352 0.095 1.575-0.038 2.298 0 2.968-1.201l-0.38 2.193h-7.78c0.308-0.191 0.543-0.401 0.543-0.858v-11.252c0-0.438-0.254-0.706-0.543-0.858h7.274l0.054 1.679z"></path>
              <path d="M0 17.735h148.36v-1.003h-148.36z"></path>
            </svg>
          </a>
        </div>
        <div id="footer-links-and-info">
          <div id="footer-links">
            <ul>
              <li>Call us: <strong>(517) 355-4470</strong></li>
              <li><Link to="/contact">Contact Information</Link></li>
              <li><Link to="/privacy-statement">Privacy Statement</Link></li>
              <li><Link to="/accessibility">Site Accessibility</Link></li>
            </ul>
          </div>
          <div id="footer-info">
            <ul>
              <li>Call MSU: <span className="msu-phone"><strong>(517) 355-1855</strong></span></li>
              <li>Visit: <strong><a href="https://msu.edu/">msu.edu</a></strong></li>
              <li>MSU is an affirmative-action, equal-opportunity employer.</li>
              <li><a href="https://oie.msu.edu/"><strong>Notice of Nondiscrimination</strong></a></li>
            </ul>
            <ul>
              <li className="spartans-will">Spartans Will.</li>
              <li>© Michigan State University</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
