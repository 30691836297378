import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LinkContainer } from 'react-router-bootstrap';

import { fetchCurrentUnit } from '../app/actions';
import * as server from '../serverAPI.js';
import ErrorAlert from '../util/ErrorAlert';


class MessageList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      request: this.props.request,
      error: null,
    };
  }
  
  async componentDidMount() {
    if (this.state.request)
      return;
    try {
      const request = await server.getRequest(this.props.requestId);
      this.setState({ request });
      if (this.props.currentUnit == null)
        this.props.fetchCurrentUnit(request.semester.unitId);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  messageList() {
    if (this.state.request == null || this.state.request.messages == null)
      return null;
    if (this.state.request.messages.length === 0)
      return <p>No message sent.</p>;
    return (
      <div className="table-responsive">
        <Table bordered size="sm" className="data">
          <thead>
            <tr>
              <th>Sent By</th>
              <th>Sent On</th>
              <th>From</th>
              <th>To</th>
              <th>Subject</th>
              <th>Attach.</th>
              <th className="text-center"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.request.messages.map(message => (
              <tr key={message.id}>
                <td>{message.userId && message.user.msuId}</td>
                <td>{(new Date(message.sentOn)).toLocaleDateString()}</td>
                <td>{message.from}</td>
                <td>{message.to}</td>
                <td>{message.subject}</td>
                <td>{message.attachments.length}</td>
                <td>
                  <LinkContainer to={'/messages/' + message.id}>
                    <Button title="View" size="xs">
                      <FontAwesomeIcon icon={faEye} title="View" />
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
  
  render() {
    const role = this.props.currentRole;
    const admin = role === 'superuser' || role === 'admin';
    return (
      <>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        { this.messageList() }
        { admin && this.state.request && !this.state.request.archived &&
          <LinkContainer to={`/requests/${this.props.requestId}/messages/create`}>
            <Button>Send a new email</Button>
          </LinkContainer>
        }
      </>
    );
  }
  
}

MessageList.propTypes = {
  requestId: PropTypes.string.isRequired,
  request: PropTypes.object,
  // from redux connect:
  currentUnit: PropTypes.object,
  currentRole: PropTypes.string,
  fetchCurrentUnit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUnit, currentRole } = state.app;
  return { currentUnit, currentRole };
};
const mapDispatchToProps = { fetchCurrentUnit };

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
