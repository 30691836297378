import React from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { LinkContainer } from 'react-router-bootstrap';

function Privacy(props) {
  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      
      <h1>Privacy Statement</h1>
      
      <p>This online privacy statement is intended to inform you of the ways in which this website (“Site”) collects information, the uses to which that information will be put, and the ways in which we protect information you choose to provide us.</p>

      <p>Please note that other Michigan State University websites may adopt different privacy practices as their specific needs require.</p>

      <p>Use of this Site is subject to all applicable state and federal laws, as well as general University policies.</p>

      <h2>Information Collected</h2>

      <p>This Site collects two general types of information: (a) information you voluntarily provide to us, which may include personal information (such as your name, address, e-mail address, etc.), and (b) information we collect automatically when you visit this Site through cookies, third party tracking technologies and server logs.</p>

      <h3>Information Voluntarily Provided by You</h3>

      <p>This Site will only collect personal information that you knowingly and voluntarily provide by, for example, responding to surveys, completing membership forms, sending e-mails, etc. The information collected by the Site is subject to MSU’s Institutional Data Policy. Generally, it is this Site’s practice to use personal information only for the purposes for which it was requested and any additional uses specifically stated on the Site. However, information collected through this Site may be subject to the University’s obligation to respond to subpoenas, court orders, discovery requests, and requests for public records under the Michigan Freedom of Information Act.</p>

      <p>The University may also access or permit access to information collected through this Site in accordance with and subject to the limitations of the University’s Acceptable Use Policy for MSU Technology Resources.</p>

      <p>Although this Site has security measures in place to protect personal information provided to us by users, users should also take steps to protect personal information by, for example, closing all web browsers after using the Site. Information disclosed in chat rooms, news groups, forums, message boards and similar contexts may become public. Users should therefore exercise caution before disclosing personal information in such contexts.</p>

      <p>The Site complies with the Payment Card Industry Data Security Standard. When you engage in financial transactions at our Site, you may be asked for a credit card number. Unless otherwise noted on the Site, credit card transactions are encrypted and the server that processes your credit card information is secure. Such information may also be saved in an encrypted format or in internal systems that are protected by a firewall system.</p>

      <h3>Information The Site Collects Automatically</h3>

      <p>This Site collects information automatically through the use of cookies, third party tracking technologies and server logs. This information does not include any personal information. We use this information primarily to monitor the use of this Site and to make improvements. We may share this data with partners in order to enhance the functionality of this Site.</p>

      <h4>Cookies</h4>

      <p>&ldquo;Cookies&rdquo; are small text files used to collect information about website activity. No personal information is collected through our use of cookies. This Site uses cookies for two main purposes: (a) to carry information about your current session at this Site from one web page to the next, which also allows you to automatically login to other Michigan State University websites, and (b) to identify you on this Site on return visits.</p>

      <p>You have the option of disabling or not accepting cookies by changing the preferences on your browser. If you opt to disable cookies, you will still be able to use certain sections of our Site. However, you will not be able to use any parts of this Site that requires a login (e.g. accessing secured content, posting to message boards, etc).</p>

      <h4>Third Party Tracking Technology</h4>

      <p>This Site does not use any third party tracking technology.</p>

      <p>You may also wish to review the <a href="http://www.reg.msu.edu/AcademicPrograms/Text.asp?Section=112#s542">MSU Access to Student Information Guidelines</a>.</p>

      <h4>Server Logs</h4>

      <p>Our web servers routinely generate logs that contain the following types of information each time the Site is used:</p>

      <ul>
        <li>The date, time and length of your visit.</li>
        <li>The path taken through our Site and the browser being used.</li>
        <li>The list of files downloaded and the amount of time spent viewing video or audio files.</li>
        <li>The IP Address of the computer accessing our Site.</li>
        <li>Any errors encountered.</li>
      </ul>

      <p>This information is used primarily to monitor the functioning and integrity of the Site and other MSU websites. It is regularly deleted.</p>

      <h2>Links</h2>

      <p>This Site may contain links to other sites. Please be aware that we do not control or claim any responsibility for the privacy practices or content of such other sites. We encourage users to read the privacy statements of each website they visit.</p>

      <h2>Choice/Opt-out</h2>

      <p>Our users are given the opportunity to receive communications from us. However, at this time this Site does not manage or send communications.</p>

      <h2>Rights for Data Subjects in the European Union</h2>

      <p>If you provide information directly to MSU from a location in the European Union, you consent to the transfer of your personal information outside the European Union to the United States.</p>

      <p>To the extent applicable, the European Union’s General Data Protection Regulation (GDPR) grants data subjects in the European Union the right, in certain circumstances, to request access to, a copy of, rectification, restriction in the use of, or erasure of your information. Your requests pursuant to the GDPR will be subject to the retention periods of applicable federal and state law. The GDPR provides further information about your rights. You also have the right to lodge complaints with your national or regional data protection authority. If you are inclined to exercise rights under the GDPR, please contact the MSU unit with which you are interacting (<a href="https://msu.edu/contactus.html">https://msu.edu/contactus.html</a>).</p>

      <h2>Disclaimer</h2>

      <p>Neither Michigan State University, nor any of its units, programs, employees, agents or individual trustees, shall be liable for any improper or incorrect use of information obtained through the use of this Site.</p>

      <h2>Consent</h2>

      <p>By using this Site you consent to the terms of this privacy statement.</p>

      <h2>Questions and feedback</h2>

      <p>Your questions, comments and concerns are always welcome. Please send us your feedback regarding the privacy statement or any other issues through the 'Contact Information' link at the very bottom of every page.</p>
    </>
  );
}

export default Privacy;
