import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NavBar from '../util/NavBar';


class SettingsNav extends Component {
  
  render() {
    const unitId = this.props.unitId;
    return (
      <NavBar items={ [
        {
          title: "All Settings",
          path: `/units/${unitId}/settings/all`,
        },
        {
          title: "Administration",
          path: `/units/${unitId}/settings/administration`,
        },
        {
          title: "Request Form",
          path: `/units/${unitId}/settings/request_form`,
        },
        {
          title: "Admin Subm. Notice",
          path: `/units/${unitId}/settings/admin_emails`,
        },
        {
          title: "Student Subm. Notice",
          path: `/units/${unitId}/settings/student_emails`,
        },
        {
          title: "Pre-built Responses",
          path: `/units/${unitId}/settings/other_emails`,
        },
      ] }/>
    );
  }
}

SettingsNav.propTypes = {
  unitId: PropTypes.number.isRequired,
};

export default SettingsNav;
