const defaultState = {
  currentUser: null,
  currentUnit: null,
  currentRole: null,
  checkedCurrentUser: false,
};

const calculateRole = (user, unit) => {
  if (user == null)
    return null;
  if (user.superuser)
    return 'superuser';
  if (user.units == null)
    return null;
  if (unit == null)
    return null;
  for (const u of user.units) {
    if (u.id === unit.id) {
      if (u.userUnit == null)
        return null;
      return u.userUnit.role;
    }
  }
  return null;
};

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: action.user,
        checkedCurrentUser: true,
        currentRole: calculateRole(action.user, state.currentUnit),
      };
    case 'SET_CURRENT_UNIT':
      return {
        ...state,
        currentUnit: action.unit,
        currentRole: calculateRole(state.currentUser, action.unit),
      };
    default:
      return state;
  }
};

export default appReducer;
