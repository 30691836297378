const defaultState = {
  units: null,
};

const unitsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_UNITS':
      return {
        ...state,
        units: action.units,
      };
    default:
      return state;
  }
};

export default unitsReducer;
