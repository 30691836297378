import * as server from '../serverAPI.js';

export const setUnits = (units) => ({
  type: 'SET_UNITS',
  units,
});

export const fetchUnits = () => {
  return async (dispatch, getState) => {
    const currentUser = getState().app.currentUser;
    if (currentUser && !currentUser.superuser && currentUser.units &&
        currentUser.units.length > 0) {
      dispatch(setUnits(currentUser.units));
    } else {
      try {
        const units = await server.getUnits();
        dispatch(setUnits(units));
      } catch (error) {
        dispatch(setUnits(null));
        throw error;
      }
    }
  };
};
