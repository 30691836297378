import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';

import { setCurrentUser, resetAppState } from './actions';
import * as server from '../serverAPI.js';


class LoginPanel extends Component {
  
  login() {
    server.samlLogin();
  }
  
  async logout() {
    try {
      await server.logout();
      this.props.resetAppState();
    } catch (err) {
      alert("Logout error: " + (err.message ? err.message : err));
    }
  }

  fetchingInfo() {
    return (
      <div className="text-center">
        Checking current user...
      </div>
    );
  }
  
  infoAndLogoutButton() {
    return (
      <div className="text-right">
        Logged in as {this.props.currentUser.msuId + ' '}
        <Button variant="secondary" size="sm" onClick={() => this.logout()}>
          Log out
        </Button>
      </div>
    );
  }
  
  loginButton() {
    return (
      <div className="text-center">
        <Button onClick={() => this.login()}>
          Login
        </Button>
      </div>
    );
  }
  
  render() {
    return (
      <div className="mb-2">
        {!this.props.checkedCurrentUser ?
          this.fetchingInfo()
          :
          this.props.currentUser ?
            this.infoAndLogoutButton()
            :
            this.loginButton()
        }
      </div>
    );
  }
  
}

LoginPanel.propTypes = {
  // from redux connect:
  currentUser: PropTypes.object,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser, checkedCurrentUser } = state.app;
  return { currentUser, checkedCurrentUser };
};
const mapDispatchToProps = { setCurrentUser, resetAppState };

export default connect(mapStateToProps, mapDispatchToProps)(LoginPanel);
