import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as server from '../serverAPI.js';


class AttachmentList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      attachments: null,
    };
  }
  
  componentDidMount() {
    this.setState({ attachments: this.props.list });
  }
  
  render() {
    if (this.state.attachments == null)
      return null;
    if (this.state.attachments.length === 0)
      return <p>No attachment.</p>;
    return (
      <Table bordered size="sm" className="data">
        <thead>
          <tr>
            <th>Filename</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {this.state.attachments.map(attachment => (
            <tr key={attachment.id}>
              <td>{attachment.fileName}</td>
              <td>
                <Button title="Download" size="xs" href={server.hostURL +
                    '/api/attachments/' + attachment.id}>
                  <FontAwesomeIcon icon={faFileDownload} title="View" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
  
}

AttachmentList.propTypes = {
  list: PropTypes.array, // list of attachments from parent object
};

export default AttachmentList;
