import { combineReducers } from 'redux';
import app from './app/reducers';
import units from './units/reducers';
import users from './users/reducers';
import requests from './requests/reducers';

const appReducer = combineReducers({ app, units, users, requests });

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP_STATE')
    state = undefined;
  const newState = appReducer(state, action);
  if (action.type === 'RESET_APP_STATE')
    newState.app.checkedCurrentUser = true;
  return newState;
};

export default rootReducer;
