import React from 'react';

function Header(props) {
  return (
    <header>
      <div className="msu-logo">
        <svg id="icon-logo" viewBox="0 0 287 32">
          <title>MSU logo</title>
          <path d="M34.909 12.996h2.715l3.006 4.817 3.006-4.817h2.715v11.464h-2.521v-7.515l-3.103 4.913h-0.097l-3.2-4.817v7.418h-2.521z"></path>
          <path d="M49.261 24.46h2.521v-11.464h-2.521z"></path>
          <path d="M54.109 18.776c0-3.276 2.424-5.973 6.012-5.973 2.133 0 3.491 0.674 4.558 1.734l-1.648 1.927c-0.873-0.771-1.842-1.252-2.909-1.252-1.939 0-3.394 1.638-3.394 3.565s1.358 3.565 3.394 3.565c1.358 0 2.133-0.482 3.006-1.349l1.648 1.638c-1.164 1.252-2.521 2.023-4.752 2.023-3.394 0-5.915-2.601-5.915-5.877z"></path>
          <path d="M66.715 12.996h2.521v4.528h4.655v-4.528h2.521v11.464h-2.521v-4.624h-4.655v4.624h-2.521z"></path>
          <path d="M79.224 24.46h2.521v-11.464h-2.521z"></path>
          <path d="M84.073 18.776v0c0-3.276 2.521-5.973 6.012-5.973 2.036 0 3.297 0.578 4.558 1.541l-1.552 2.023c-0.873-0.771-1.648-1.156-3.006-1.156-1.842 0-3.297 1.638-3.297 3.565 0 2.119 1.455 3.661 3.491 3.661 0.97 0 1.745-0.193 2.424-0.674v-1.638h-2.521v-2.216h5.042v5.010c-1.164 0.963-2.812 1.83-4.945 1.83-3.685-0.096-6.206-2.601-6.206-5.973z"></path>
          <path d="M104.048 19.74l-1.552-3.757-1.552 3.757h3.103zM101.43 12.9h2.327l4.945 11.561h-2.618l-1.067-2.601h-4.849l-1.067 2.601h-2.618l4.945-11.561z"></path>
          <path d="M110.351 12.996h2.327l5.43 7.033v-7.033h2.521v11.464h-2.133l-5.624-7.322v7.322h-2.521z"></path>
          <path d="M127.418 22.823l1.455-1.83c1.067 0.867 2.133 1.349 3.491 1.349 1.067 0 1.649-0.385 1.649-1.061 0-0.673-0.388-0.963-2.327-1.445-2.327-0.577-3.782-1.251-3.782-3.467 0-2.119 1.649-3.468 4.073-3.468 1.649 0 3.103 0.482 4.267 1.445l-1.358 1.927c-1.067-0.674-2.036-1.156-3.006-1.156s-1.455 0.482-1.455 0.963c0 0.771 0.485 0.963 2.521 1.541 2.327 0.578 3.685 1.445 3.685 3.468 0 2.312-1.745 3.564-4.267 3.564-1.842 0-3.588-0.577-4.945-1.829z"></path>
          <path d="M141.091 15.308h-3.491v-2.312h9.6v2.312h-3.491v9.152h-2.521v-9.152z"></path>
          <path d="M154.182 19.74l-1.552-3.757-1.552 3.757h3.103zM151.467 12.9h2.327l4.945 11.561h-2.618l-1.067-2.601h-4.849l-1.067 2.601h-2.618l4.945-11.561z"></path>
          <path d="M161.649 15.308h-3.492v-2.312h9.601v2.312h-3.491v9.152h-2.521v-9.152z"></path>
          <path d="M169.6 12.996h8.727v2.216h-6.206v2.312h5.429v2.216h-5.429v2.409h6.303v2.216h-8.824z"></path>
          <path d="M185.503 19.74v-6.647h1.261v6.551c0 2.505 1.358 3.854 3.491 3.854s3.491-1.252 3.491-3.757v-6.647h1.261v6.551c0 3.372-1.939 5.106-4.849 5.106-2.715-0.096-4.655-1.83-4.655-5.010z"></path>
          <path d="M198.109 12.996h1.261l7.273 9.152v-9.152h1.262v11.464h-1.068l-7.467-9.441v9.441h-1.261z"></path>
          <path d="M211.103 24.46h1.261v-11.464h-1.261z"></path>
          <path d="M214.788 12.996h1.455l4.073 9.827 4.073-9.827h1.358l-4.945 11.561h-1.164z"></path>
          <path d="M227.975 12.996h8.34v1.156h-7.079v3.95h6.303v1.156h-6.303v4.046h7.079v1.156h-8.437v-11.464z"></path>
          <path d="M243.879 18.872c1.745 0 3.006-0.866 3.006-2.311s-1.067-2.217-2.909-2.217h-3.588v4.625h3.491v-0.097zM239.030 12.996h4.945c1.455 0 2.521 0.385 3.297 1.157 0.582 0.577 0.873 1.348 0.873 2.215 0 1.927-1.358 2.987-3.103 3.372l3.588 4.722h-1.552l-3.297-4.433h-3.297v4.433h-1.261v-11.465h-0.194z"></path>
          <path d="M250.085 22.823l0.776-0.963c1.164 1.060 2.326 1.638 3.975 1.638 1.552 0 2.619-0.772 2.619-1.927 0-1.060-0.582-1.638-3.006-2.217-2.618-0.577-3.879-1.445-3.879-3.275s1.552-3.083 3.781-3.083c1.649 0 2.909 0.482 4.074 1.445l-0.776 0.963c-1.068-0.867-2.133-1.252-3.298-1.252-1.455 0-2.424 0.771-2.424 1.83s0.583 1.734 3.104 2.216c2.618 0.578 3.782 1.445 3.782 3.276 0 1.927-1.649 3.276-3.977 3.276-1.841-0.097-3.394-0.674-4.751-1.927z"></path>
          <path d="M261.43 24.46h1.262v-11.464h-1.262z"></path>
          <path d="M269.091 14.248h-3.879v-1.156h9.018v1.156h-3.879v10.308h-1.358v-10.308z"></path>
          <path d="M280.242 19.933l-4.752-6.936h1.551l3.879 5.78 3.879-5.78h1.551l-4.752 6.936v4.528h-1.358z"></path>
          <path d="M25.988 2.206c0 0-1.648 3.565-2.521 5.299-4.17-1.927-9.6-2.119-14.158 0.482-3.006 1.83-5.527 5.106-5.721 8.671-0.097 2.312 0.97 4.914 0.582 7.323-0.097 1.251-0.388 2.504-0.97 3.564-0.776 1.253-1.649 2.312-3.2 2.409 0.291-0.385 2.715-1.541 1.745-6.84-0.388-2.119-3.297-7.322-0.485-13.777 2.618-5.973 13.673-13.006 24.727-7.129z"></path>
          <path d="M13.285 12.611c1.455-0.482 3.006-0.482 4.558-0.193 2.909 0.482 5.042 2.216 6.109 4.817 1.067 2.698 1.842 4.817 3.782 7.129 0.097 0.096-2.909-0.482-2.909-0.482 0.776-5.299-4.267-5.491-5.721-3.565 1.067 1.156 2.812 1.734 4.267 1.541-0.291 5.299 1.164 10.019 1.067 10.019-1.164-0.193-6.691-3.372-8.339-4.817-2.327 0.385-4.267 1.252-6.982 1.638-0.097 0 0.485-2.023 0.388-2.987 0-2.794-2.133-5.395-0.582-8.767 0.873-2.119 2.23-3.565 4.364-4.335z"></path>
          <path d="M15.224 10.973h0.388c0-0.193 0.194-1.541 1.358-2.409 1.261-1.060 2.909-0.963 2.909-0.963-1.648-0.482-3.297-0.578-4.461-0.385l-0.194 3.757z"></path>
          <path d="M4.848 15.79c-0.291 1.541-0.291 3.083-0.097 3.854 0 0 0.194-1.349 0.97-2.119 0.679-0.674 1.551-0.771 1.842-0.771 0 0 0.194-0.482 0.097-0.482l-2.812-0.482z"></path>
          <path d="M7.952 15.501c0.097-0.193 0.194-0.385 0.291-0.674l-2.909-1.156c-0.194 0.385-0.291 0.867-0.388 1.156l3.006 0.674z"></path>
          <path d="M8.727 14.441l0.485-0.578-2.715-2.023c-0.291 0.289-0.485 0.674-0.679 1.156l2.909 1.445z"></path>
          <path d="M9.697 13.285l0.582-0.578-2.327-2.601c-0.485 0.482-0.679 0.674-0.97 1.060l2.715 2.119z"></path>
          <path d="M10.861 12.418l0.679-0.385-1.745-3.083c-0.582 0.289-1.067 0.578-1.261 0.771l2.327 2.698z"></path>
          <path d="M12.121 11.744l0.776-0.289-0.97-3.468c-0.582 0.193-1.261 0.482-1.455 0.578l1.649 3.179z"></path>
          <path d="M13.576 11.262l0.776-0.193 0.097-3.757c0 0-0.873 0.193-1.649 0.385l0.776 3.565z"></path>
        </svg>
      </div>
      <div className="college-and-app-title">
        <span className="header-unitName">College of Natural Science</span>
        <span className="header-appName"> - Override Requests</span>
      </div>
    </header>
  );
}

export default Header;
