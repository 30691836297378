import React from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import PropTypes from 'prop-types';

function ConfirmModal(props) {
  return (
    <Modal show={props.show} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>Please Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to {props.whatToDo} ?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.cancelled}>
          Cancel
        </Button>
        <Button variant="primary" onClick={props.confirmed}>
          {props.action}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  whatToDo: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  confirmed: PropTypes.func.isRequired,
  cancelled: PropTypes.func.isRequired,
};

export default ConfirmModal;
