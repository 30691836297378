import React from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { LinkContainer } from 'react-router-bootstrap';

function Contact(props) {
  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <h1>Contact</h1>
      <p>For questions about this application, please contact <a href="mailto:natsci.ticket@msu.edu">natsci.ticket@msu.edu</a>.</p>
      <p>Questions related to specific units should be directed to them.</p>
    </>
  );
}

export default Contact;
