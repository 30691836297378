import * as server from '../serverAPI.js';

export const setUsers = (users) => ({
  type: 'SET_USERS',
  users,
});

export const fetchUsers = () => {
  return async (dispatch, getState) => {
    try {
      const users = await server.getUsers();
      dispatch(setUsers(users));
    } catch (error) {
      dispatch(setUsers(null));
      throw error;
    }
  };
};
