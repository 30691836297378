import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';

import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { setCurrentUnit } from '../app/actions';
import * as server from '../serverAPI.js';
import ErrorAlert from '../util/ErrorAlert';
import UnitNav from './UnitNav';


class Statistics extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      semesters: null,
      reasons: null,
      error: null,
    };
  }
  
  async componentDidMount() {
    const unitId = parseInt(this.props.match.params.unitId);
    if (isNaN(unitId)) {
      this.setState({ error: "unit id is not an integer" });
      return;
    }
    try {
      if (!this.props.currentUnit || this.props.currentUnit.id !== unitId) {
        const unit = await server.getUnit(unitId);
        this.props.setCurrentUnit(unit);
      }
      const { semesters, reasons } = await server.getUnitStatistics(unitId);
      this.setState({ semesters, reasons });
    } catch (error) {
      this.setState({ error: error.message });
    }
    document.title = "Override Requests - Statistics";
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        {this.props.currentRole === 'superuser' &&
          <>
            <LinkContainer to="/units">
              <Breadcrumb.Item>Units</Breadcrumb.Item>
            </LinkContainer>
            <LinkContainer to={'/units/' + this.props.match.params.unitId}>
              <Breadcrumb.Item>Unit</Breadcrumb.Item>
            </LinkContainer>
          </>
        }
        <Breadcrumb.Item active>Statistics</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  semesterList() {
    if (this.state.semesters == null)
      return null;
    if (this.state.semesters.length === 0) {
      return (
        <p>No request in this unit yet.</p>
      );
    }
    // prepare array of statuses, ordered by global count
    const statuses = [];
    const statusHash = {};
    let totalAllSemesters = 0;
    for (const semester of this.state.semesters) {
      let requests = 0;
      for (const s of semester.statuses) {
        let status = statusHash[s.status];
        if (!status) {
          status = {
            name: s.status,
            count: s.count,
          };
          statusHash[status.name] = status;
          statuses.push(status);
        } else {
          status.count += s.count;
        }
        requests += s.count;
      }
      semester.requests = requests;
      totalAllSemesters += requests;
    }
    statuses.sort((s1, s2) => s2.count - s1.count);
    return (
      <section className="border">
        <h2>Request status</h2>
        <div className="table-responsive">
          <Table bordered size="sm" className="data table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th>Semester</th>
                {statuses.map(s => (
                  <th key={s.name}>{s.name}</th>
                ))}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {this.state.semesters.map(semester => (
                <tr key={semester.id}>
                  <td className="code"><Link to={'/units/'+this.props.match.params.unitId +
                  '/semesters/' + semester.id}>{semester.title}</Link></td>
                  {statuses.map(status => {
                    let count = 0;
                    const matchingStatus = semester.statuses.find(
                      s => s.status === status.name);
                    if (matchingStatus)
                      count = matchingStatus.count;
                    return (
                      <td key={status.name}>{count}</td>
                    );
                  })}
                  <td>{semester.requests}</td>
                </tr>
              ))}
              <tr>
                <th>Total</th>
                {statuses.map(status => (
                  <td key={status.name}>{status.count}</td>
                ))}
                <td>{totalAllSemesters}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </section>
    );
  }
  
  reasonList() {
    if (this.state.reasons == null || this.state.reasons.length === 0)
      return null;
    return (
      <section className="border">
        <h2>Reasons</h2>
        <div className="table-responsive">
          <Table bordered size="sm" className="data table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th>Reason</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {this.state.reasons.map(reason => (
                <tr key={reason.requestReason}>
                  <td>{reason.requestReason}</td>
                  <td>{reason.count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </section>
    );
  }
  
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <UnitNav/>
        <h1>Statistics</h1>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        { this.semesterList() }
        { this.reasonList() }
      </>
    );
  }
  
}

Statistics.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string.isRequired,
    })
  }),
  // from redux connect:
  currentUnit: PropTypes.object,
  currentRole: PropTypes.string.isRequired,
  setCurrentUnit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUnit, currentRole } = state.app;
  return { currentUnit, currentRole };
};
const mapDispatchToProps = { setCurrentUnit };

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
