const defaultState = {
  savedSemesterId: null,
  savedRequestType: 'current',
};

const requestsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_SAVED_SEMESTER_ID':
      return {
        ...state,
        savedSemesterId: action.semesterId,
      };
    case 'SET_SAVED_REQUEST_TYPE':
      return {
        ...state,
        savedRequestType: action.requestType,
      };
    default:
      return state;
  }
};

export default requestsReducer;
