import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NavBar from '../util/NavBar';


class UnitNav extends Component {
  
  render() {
    if (!this.props.currentUnit)
      return null;
    const unitId = this.props.currentUnit.id;
    return (
      <NavBar items={ [
        {
          title: "Edit unit",
          path: `/units/${unitId}`,
          condition: this.props.currentRole === 'superuser',
        },
        {
          title: "Unit Settings",
          path: `/units/${unitId}/settings`,
        },
        {
          title: "Semesters",
          path: `/units/${unitId}/semesters`,
        },
        {
          title: "Statistics",
          path: `/units/${unitId}/statistics`,
        },
        {
          title: "Help",
          path: '/help',
        },
      ] }/>
    );
  }
}

UnitNav.propTypes = {
  // from redux connect:
  currentUnit: PropTypes.object,
  currentRole: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { currentUnit, currentRole } = state.app;
  return { currentUnit, currentRole };
};

export default connect(mapStateToProps)(UnitNav);
