import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { LinkContainer } from 'react-router-bootstrap';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { setCurrentUnit, fetchCurrentUnit } from '../app/actions';
import * as server from '../serverAPI.js';
import ErrorAlert from '../util/ErrorAlert';
import SettingsNav from './SettingsNav';
import SuccessAlert from '../util/SuccessAlert';
import UnitNav from './UnitNav';


class Settings extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      settings: null,
      error: null,
      success: null,
    };
  }
  
  subsetTitle(subset) {
    const subsetTitles = {
      all: "All",
      administration: "Administration",
      request_form: "Request Form",
      admin_emails: "Admin Submission Notice Email",
      student_emails: "Student Submission Notice Email",
      other_emails: "Pre-built Email Responses",
    };
    return subsetTitles[subset];
  }
  
  documentTitle() {
    if (this.props.match.params.subset)
      return "Unit Settings: " + this.subsetTitle(this.props.match.params.subset);
    else if (this.props.currentUnit)
      return "Unit Settings - " + this.props.currentUnit.name;
    return "Unit Settings";
  }
  
  async componentDidMount() {
    const unitId = parseInt(this.props.match.params.unitId);
    if (isNaN(unitId)) {
      this.setState({ error: "unit id is not an integer" });
      return;
    }
    try {
      const unit = await server.getUnit(unitId);
      if (!this.props.currentUnit || this.props.currentUnit.id !== unit.id)
        this.props.setCurrentUnit(unit);
      const settings = unit.settings;
      for (const s in settings) {
        if (settings[s] == null)
          settings[s] = '';
      }
      this.setState({ settings });
      document.title = this.documentTitle();
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.subset !== prevProps.match.params.subset)
      this.setState({ error: null, success: null });
    if (this.props.match.params.currentUnit !== prevProps.match.params.currentUnit ||
        this.props.match.params.subset !== prevProps.match.params.subset)
      document.title = this.documentTitle();
  }
  
  handleSettingsChange(event) {
    const target = event.target;
    this.setState({
      settings: {
        ...this.state.settings,
        [target.name]: target.value
      }
    });
  }
  
  async saveSettings() {
    this.setState({ error: null, success: null });
    try {
      await server.updateSettings(this.state.settings);
      this.setState({ success: "The settings were successfully saved." });
      document.title = "Settings " + this.state.settings.id;
      // reload app currentUnit with new settings:
      this.props.fetchCurrentUnit(this.props.match.params.unitId);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        {this.props.currentRole === 'superuser' &&
          <>
            <LinkContainer to="/units">
              <Breadcrumb.Item>Units</Breadcrumb.Item>
            </LinkContainer>
            <LinkContainer to={'/units/' + this.props.match.params.unitId}>
              <Breadcrumb.Item>Unit</Breadcrumb.Item>
            </LinkContainer>
          </>
        }
        {this.props.match.params.subset ?
          <>
            <LinkContainer to={'/units/' + this.props.match.params.unitId + '/settings'}>
              <Breadcrumb.Item>Settings</Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active>Settings</Breadcrumb.Item>
          </>
          :
          <Breadcrumb.Item active>Settings</Breadcrumb.Item>
        }
      </Breadcrumb>
    );
  }
  
  allSettings() {
    return <>
      <fieldset>
        <legend>{this.subsetTitle('administration')}</legend>
        {this.administration()}
      </fieldset>
      <fieldset>
        <legend>{this.subsetTitle('request_form')}</legend>
        {this.requestForm()}
      </fieldset>
      <fieldset>
        <legend>{this.subsetTitle('admin_emails')}</legend>
        {this.adminEmails()}
      </fieldset>
      <fieldset>
        <legend>{this.subsetTitle('student_emails')}</legend>
        {this.studentEmails()}
      </fieldset>
      <fieldset>
        <legend>{this.subsetTitle('other_emails')}</legend>
        {this.otherEmails()}
      </fieldset>
    </>;
  }
  
  administration() {
    return (
      <>
        <p>Column Names: <tt>pid firstname lastname email phone major
          classLevel expectedGraduation course courseCode sections credits
          reason requestReason comments status approvedSections adminNotes
          dateSubmitted dateEntered dateEnrolled dateConfirmationSent admin</tt></p>
        <Form.Group controlId="requestListTableColumns">
          <Form.Label>Request List Table Columns (comma-separated, no space)</Form.Label>
          <Form.Control name="requestListTableColumns" type="text" maxLength="255"
            value={this.state.settings.requestListTableColumns}
            pattern="[a-zA-Z]+(,[a-zA-Z]+)*" required
            onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="archiveListTableColumns">
          <Form.Label>Archive List Table Columns (comma-separated, no space)</Form.Label>
          <Form.Control name="archiveListTableColumns" type="text" maxLength="255"
            value={this.state.settings.archiveListTableColumns}
            pattern="[a-zA-Z]+(,[a-zA-Z]+)*" required
            onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
      </>
    );
  }
  
  requestForm() {
    return (
      <>
        <p>Available fields: <tt>msuId pid firstname lastname email phone major
          classLevel expectedGraduation courseSubject courseCode sections
          semesterId credits requestReason otherReason completePrereq
          prereqNeeded transferPrereq prereqTransfer comments attachments</tt></p>
        <Form.Group controlId="formDisplayedFields">
          <Form.Label>Displayed Fields (comma-separated, no space)</Form.Label>
          <Form.Control name="formDisplayedFields" as="textarea" rows="3"
            value={this.state.settings.formDisplayedFields} spellCheck="false"
            required onChange={e => this.handleSettingsChange(e)}
            onKeyUp={e => {
              // the pattern attribute does not exist for textarea
              e.target.setCustomValidity(
                /^[a-zA-Z]+(,[a-zA-Z]+)*$/.test(e.target.value) ? '' :
                  "Use only letters and commas");
            }}/>
        </Form.Group>
        <Form.Group controlId="formIntro">
          <Form.Label>Introduction (HTML)</Form.Label>
          {/*<Form.Control name="formIntro" as="textarea" rows="5"
            value={this.state.settings.formIntro}
            required onChange={e => this.handleSettingsChange(e)}/>*/}
          <CKEditor editor={ ClassicEditor }
            config={{
              toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                '|',
                'link',
                '|',
                'bulletedList',
                'numberedList',
                'indent',
                'outdent',
                '|',
                'insertTable',
                '|',
                'undo',
                'redo'
              ],
            }}
            data={this.state.settings.formIntro}
            onChange={(e, editor) => this.setState({
              settings: {
                ...this.state.settings,
                formIntro: editor.getData(),
              }
            })}/>
        </Form.Group>
        <Form.Group controlId="formCourseSubjects">
          <Form.Label>Course Subjects (comma-separated, no space)</Form.Label>
          <Form.Control name="formCourseSubjects" type="text" maxLength="50"
            value={this.state.settings.formCourseSubjects}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="formReasons">
          <Form.Label>Predefined request reasons (comma-separated)</Form.Label>
          <Form.Control name="formReasons" as="textarea" rows="5"
            value={this.state.settings.formReasons}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="formSubmissionSuccess">
          <Form.Label>Submission Success Text</Form.Label>
          <Form.Control name="formSubmissionSuccess" as="textarea" rows="5"
            value={this.state.settings.formSubmissionSuccess}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="formSubmissionError">
          <Form.Label>Submission Error Text</Form.Label>
          <Form.Control name="formSubmissionError" as="textarea" rows="5"
            value={this.state.settings.formSubmissionError}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
      </>
    );
  }
  
  variables() {
    return (
      <p>Variables: <tt>[firstname] [lastname] [email] [phone] [major]
        [classLevel] [expectedGraduation] [courseSubject] [courseCode]
        [sections] [semester] [credits] [requestReason] [otherReason]
        [completePrereq] [prereqNeeded] [transferPrereq] [prereqTransfer]
        [comments] [unit] [adminFullName] [requestURL]</tt>.</p>
    );
  }
  
  adminEmails() {
    return (
      <>
        {this.variables()}
        <Form.Group controlId="adminEmailFrom">
          <Form.Label>From Address</Form.Label>
          <Form.Control name="adminEmailFrom" type="email" maxLength="100"
            value={this.state.settings.adminEmailFrom}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="adminEmailTo">
          <Form.Label>To Address(es) (comma-separated)</Form.Label>
          <Form.Control name="adminEmailTo" type="text" maxLength="255"
            value={this.state.settings.adminEmailTo}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="adminEmailCc">
          <Form.Label>CC Address(es) (comma-separated)</Form.Label>
          <Form.Control name="adminEmailCc" type="text" maxLength="255"
            value={this.state.settings.adminEmailCc}
            onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="adminEmailBcc">
          <Form.Label>BCC Address(es) (comma-separated)</Form.Label>
          <Form.Control name="adminEmailBcc" type="text" maxLength="255"
            value={this.state.settings.adminEmailBcc}
            onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="adminEmailSubject">
          <Form.Label>Subject</Form.Label>
          <Form.Control name="adminEmailSubject" type="text" maxLength="255"
            value={this.state.settings.adminEmailSubject}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="adminEmailBody">
          <Form.Label>Body</Form.Label>
          <Form.Control name="adminEmailBody" as="textarea" rows="5"
            value={this.state.settings.adminEmailBody}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
      </>
    );
  }
  
  studentEmails() {
    return (
      <>
        {this.variables()}
        <Form.Group controlId="studentEmailFrom">
          <Form.Label>From Address</Form.Label>
          <Form.Control name="studentEmailFrom" type="email" maxLength="100"
            value={this.state.settings.studentEmailFrom}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="studentEmailCc">
          <Form.Label>CC Address(es) (comma-separated)</Form.Label>
          <Form.Control name="studentEmailCc" type="text" maxLength="255"
            value={this.state.settings.studentEmailCc}
            onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="studentEmailBcc">
          <Form.Label>BCC Address(es) (comma-separated)</Form.Label>
          <Form.Control name="studentEmailBcc" type="text" maxLength="255"
            value={this.state.settings.studentEmailBcc}
            onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="studentEmailSubject">
          <Form.Label>Subject</Form.Label>
          <Form.Control name="studentEmailSubject" type="text" maxLength="255"
            value={this.state.settings.studentEmailSubject}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="studentEmailBody">
          <Form.Label>Body</Form.Label>
          <Form.Control name="studentEmailBody" as="textarea" rows="5"
            value={this.state.settings.studentEmailBody}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
      </>
    );
  }
  
  otherEmails() {
    return (
      <>
        {this.variables()}
        <Form.Group controlId="updateEmailSubject">
          <Form.Label>Update Email Subject</Form.Label>
          <Form.Control name="updateEmailSubject" type="text" maxLength="255"
            value={this.state.settings.updateEmailSubject}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="updateEmailBody">
          <Form.Label>Update Email Body</Form.Label>
          <Form.Control name="updateEmailBody" as="textarea" rows="6"
            value={this.state.settings.updateEmailBody}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="deniedEmailSubject">
          <Form.Label>Denied Email Subject</Form.Label>
          <Form.Control name="deniedEmailSubject" type="text" maxLength="255"
            value={this.state.settings.deniedEmailSubject}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="deniedEmailBody">
          <Form.Label>Denied Email Body</Form.Label>
          <Form.Control name="deniedEmailBody" as="textarea" rows="6"
            value={this.state.settings.deniedEmailBody}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="grantedEmailSubject">
          <Form.Label>Granted Email Subject</Form.Label>
          <Form.Control name="grantedEmailSubject" type="text" maxLength="255"
            value={this.state.settings.grantedEmailSubject}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
        <Form.Group controlId="grantedEmailBody">
          <Form.Label>Granted Email Body</Form.Label>
          <Form.Control name="grantedEmailBody" as="textarea" rows="6"
            value={this.state.settings.grantedEmailBody}
            required onChange={e => this.handleSettingsChange(e)}/>
        </Form.Group>
      </>
    );
  }
  
  subsets() {
    switch (this.props.match.params.subset) {
      case 'all': return this.allSettings();
      case 'administration': return this.administration();
      case 'request_form': return this.requestForm();
      case 'admin_emails': return this.adminEmails();
      case 'student_emails': return this.studentEmails();
      case 'other_emails': return this.otherEmails();
      default: return null;
    }
  }
  
  render() {
    const unitId = parseInt(this.props.match.params.unitId);
    return (
      <>
        {this.breadcrumbs()}
        <UnitNav/>
        <h1>{this.documentTitle()}</h1>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        <SuccessAlert message={this.state.success}
          onClose={() => this.setState({ success: null })}/>
        <SettingsNav unitId={unitId}/>
        {this.state.settings && this.props.match.params.subset &&
        <Form onSubmit={e => { e.preventDefault(); this.saveSettings(); } } className="form">
          {this.subsets()}
          {this.props.match.params.subset &&
            <div>
              <Button variant="primary" type="submit" size="lg">
                Save
              </Button>
            </div>
          }
        </Form>
        }
      </>
    );
  }
  
}

Settings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string.isRequired,
      subset: PropTypes.string,
    })
  }),
  // from redux connect:
  currentRole: PropTypes.string.isRequired,
  currentUnit: PropTypes.object,
  setCurrentUnit: PropTypes.func.isRequired,
  fetchCurrentUnit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUnit, currentRole } = state.app;
  return { currentUnit, currentRole };
};
const mapDispatchToProps = { setCurrentUnit, fetchCurrentUnit };

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
