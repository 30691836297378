import React, { PureComponent } from 'react';

import Alert from 'react-bootstrap/Alert';

import PropTypes from 'prop-types';

class ErrorAlert extends PureComponent {
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.message && !prevProps.message)
      document.querySelector('.alert').focus();
  }
  
  render() {
    return (
      <Alert show={this.props.message != null} variant="danger" dismissible
        onClose={() => this.props.onClose()} tabIndex="0">
        <p>{this.props.message}</p>
        <p>Please let us know about issues at <a href="mailto:natsci.ticket@msu.edu">natsci.ticket@msu.edu</a>.</p>
      </Alert>
    );
  }
}

ErrorAlert.propTypes = {
  message: PropTypes.string, // the alert will not display when null
  onClose: PropTypes.func.isRequired,
};

export default ErrorAlert;
