import React, { Component } from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';

import UpdateList from './UpdateList';


class UpdateListPage extends Component {
  
  componentDidMount() {
    document.title = "Override Requests - Updates";
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/requests">
          <Breadcrumb.Item>Requests</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to={'/requests/' + this.props.match.params.requestId}>
          <Breadcrumb.Item>Request</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Updates</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <h1>Request Updates</h1>
        <UpdateList
          match={ { params: { requestId: this.props.match.params.requestId } } }/>
      </>
    );
  }
  
}

UpdateListPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string.isRequired,
    })
  }),
};

export default UpdateListPage;
