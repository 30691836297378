import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { wrapHistory } from 'oaf-react-router';

import { fetchCurrentUnit, fetchCurrentUser }
  from './actions';
import './App.scss';
import Accessibility from './Accessibility';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';
import Help from '../units/Help';
import Home from './Home';
import LoginPanel from './LoginPanel';
import Message from '../requests/Message';
import MessageListPage from '../requests/MessageListPage';
import Privacy from './Privacy';
import RequestContainer from '../requests/RequestContainer';
import RequestListPage from '../requests/RequestListPage';
import Semester from '../units/Semester';
import Settings from '../units/Settings';
import SessionList from '../users/SessionList';
import Statistics from '../units/Statistics';
import Unit from '../units/Unit';
import UnitList from '../units/UnitList';
import UnitSemesterList from '../units/UnitSemesterList';
import UpdateListPage from '../requests/UpdateListPage';
import User from '../users/User';
import UserList from '../users/UserList';


class App extends Component {

  constructor() {
    super();
    this.history = createBrowserHistory();
    wrapHistory(this.history);
  }

  componentDidMount() {
    this.getCurrentUser();
  }
  
  async getCurrentUser() {
    try {
      await this.props.fetchCurrentUser();
    } catch (err) {
      alert(err);
    }
  }

  async unitSelection(unitId) {
    try {
      await this.props.fetchCurrentUnit(unitId);
    } catch (err) {
      alert(err);
    }
  }
  
  routerSwitch() {
    const { currentUnit, currentRole } = this.props;
    const superuser = currentRole === 'superuser';
    const admin = superuser || currentRole === 'admin';
    return (
      <Switch>
        <Route path="/unit-selection/:unitId(\d+)" render={(rProps) => {
          this.unitSelection(rProps.match.params.unitId);
          return (<Redirect to="/"/>);
        }} />
        
        <Route path="/contact" component={Contact}/>
        <Route path="/privacy-statement" component={Privacy}/>
        <Route path="/accessibility" component={Accessibility}/>
        
        {!superuser &&
          <Route path="/users/">
            <Redirect to="/"/>
          </Route>
        }
        <Route path="/users/sessions" component={SessionList} />
        <Route path="/users/create" component={User}/>
        <Route path="/users/:userId(\d+)" component={User}/>
        <Route path="/users/" component={UserList} />
        
        {!admin &&
          <Route path="/units/">
            <Redirect to="/"/>
          </Route>
        }
        {superuser &&
          <Route path="/units/create" component={Unit}/>
        }
        <Route path="/units/:unitId(\d+)/semesters/create" component={Semester}/>
        <Route path="/units/:unitId(\d+)/semesters/:semesterId(\d+)" component={Semester}/>
        <Route path="/units/:unitId(\d+)/semesters" component={UnitSemesterList}/>
        <Route path="/units/:unitId(\d+)/statistics" component={Statistics}/>
        <Route path="/units/:unitId(\d+)/settings/:subset?" component={Settings}/>
        {!superuser &&
          <Route path="/units/">
            <Redirect to="/"/>
          </Route>
        }
        <Route path="/units/:unitId(\d+)" component={Unit} />
        <Route path="/units/" component={UnitList} />
        
        {currentUnit && admin &&
          <Route path="/requests/:requestId(\d+)/messages/create" component={Message}/>
        }
        <Route path="/messages/:messageId(\d+)" component={Message}/>
        <Route path="/requests/:requestId(\d+)/messages/" component={MessageListPage}/>
        {admin &&
          <Route path="/requests/:requestId(\d+)/updates/" component={UpdateListPage}/>
        }
        {currentUnit &&
          <Route path="/requests/create/" component={RequestContainer}/>
        }
        <Route path="/requests/:requestId(\d+)/" component={RequestContainer}/>
        {currentUnit &&
          <Route path="/requests/" component={RequestListPage}/>
        }
        
        {currentUnit && admin &&
          <Route path="/help" component={Help}/>
        }
        
        <Route component={Home}/>
      </Switch>
    );
  }
  
  render() {
    return (
      <Router history={this.history}>
        <Header/>
        <main>
          {!this.props.currentUser &&
            <h1>Override Requests</h1>}
          <LoginPanel/>
          {this.props.currentUser &&
            this.routerSwitch()
          }
        </main>
        <Footer/>
      </Router>
    );
  }
  
}

App.propTypes = {
  // from redux connect:
  currentUser: PropTypes.object,
  currentUnit: PropTypes.object,
  currentRole: PropTypes.string,
  fetchCurrentUnit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser, currentUnit, currentRole } = state.app;
  return { currentUser, currentUnit, currentRole };
};
const mapDispatchToProps = { fetchCurrentUnit, fetchCurrentUser };
const options = { forwardRef: true };

export default connect(mapStateToProps, mapDispatchToProps, null, options)(App);
