import React, { Component } from 'react';

import Table from 'react-bootstrap/Table';

import PropTypes from 'prop-types';


class SortedTable extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      records: [...this.props.records],
      primarySort: null,
      secondarySort: null,
    };
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.records !== prevProps.records ||
        JSON.stringify(this.state.primarySort) !==
          JSON.stringify(prevState.primarySort) ||
        JSON.stringify(this.state.secondarySort) !==
          JSON.stringify(prevState.secondarySort)) {
      this.sortRecords();
    }
  }
  
  changeSorting(field) {
    let primarySort = this.state.primarySort == null ? null :
      Object.assign({}, this.state.primarySort);
    let secondarySort = this.state.secondarySort == null ? null :
      Object.assign({}, this.state.secondarySort);
    if (primarySort == null) {
      primarySort = { field, order: 1 };
    } else if (primarySort.field === field) {
      if (primarySort.order === 1) {
        primarySort.order = -1;
      } else {
        if (secondarySort == null) {
          primarySort = null;
        } else {
          primarySort = secondarySort;
          secondarySort = null;
        }
      }
    } else if (secondarySort == null) {
      secondarySort = { field, order: 1 };
    } else if (secondarySort.field === field) {
      if (secondarySort.order === 1)
        secondarySort.order = -1;
      else
        secondarySort = null;
    } else {
      primarySort = secondarySort;
      secondarySort = { field, order: 1 };
    }
    this.setState({ primarySort, secondarySort });
  }
  
  sortRecords() {
    const primarySort = this.state.primarySort;
    const secondarySort = this.state.secondarySort;
    const records = [...this.props.records];
    if (primarySort == null) {
      this.setState({ records });
      return;
    }
    records.sort((r1, r2) => {
      const pf = primarySort.field;
      const r1pf = this.props.recordValueToSort(r1, pf);
      const r2pf = this.props.recordValueToSort(r2, pf);
      let compare;
      if (typeof r1pf === 'string' && typeof r2pf === 'string')
        compare = r1pf.localeCompare(r2pf);
      else
        compare = (r1pf > r2pf) - (r1pf < r2pf);
      if (compare !== 0)
        return compare * primarySort.order;
      if (secondarySort == null)
        return 0;
      const sf = secondarySort.field;
      const r1sf = this.props.recordValueToSort(r1, sf);
      const r2sf = this.props.recordValueToSort(r2, sf);
      if (typeof r1sf === 'string' && typeof r2sf === 'string')
        compare = r1sf.localeCompare(r2sf);
      else
        compare = (r1sf > r2sf) - (r1sf < r2sf);
      return compare * secondarySort.order;
    });
    this.setState({ records });
  }
  
  thSortIcon(field) {
    let c = null;
    if (this.state.primarySort && this.state.primarySort.field === field) {
      if (this.state.primarySort.order === 1)
        c = '▼';
      else
        c = '▲';
    } else if (this.state.secondarySort && this.state.secondarySort.field === field) {
      if (this.state.secondarySort.order === 1)
        c = '▼';
      else
        c = '▲';
    }
    return c;
  }
  
  render() {
    return (
      <div className="table-responsive">
        <Table bordered size="sm" className="data">
          <thead>
            <tr>
              {this.props.fields.map(([field, title]) =>
                field === 'actions' ?
                  <th key={field}>Actions</th>
                  :
                  <th key={field} style={{cursor:'pointer'}} tabIndex="0"
                  onClick={() => this.changeSorting(field)}
                  onKeyDown={(e) => e.keyCode === 13 && this.changeSorting(field)}>
                    {title}
                    {this.thSortIcon(field)}
                  </th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.state.records.map(record =>
              this.props.recordTableRow(record)
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

SortedTable.propTypes = {
  fields: PropTypes.array.isRequired,
  records: PropTypes.array.isRequired,
  recordTableRow: PropTypes.func.isRequired,
  recordValueToSort: PropTypes.func,
};

SortedTable.defaultProps = {
  recordValueToSort: (record, field) => record[field]
};

export default SortedTable;
