export const setSavedSemesterId = (semesterId) => {
  return {
    type: 'SET_SAVED_SEMESTER_ID',
    semesterId,
  };
};

export const setSavedRequestType = (requestType) => ({
  type: 'SET_SAVED_REQUEST_TYPE',
  requestType,
});
