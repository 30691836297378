import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';


class AttachmentForm extends Component {
  
  render() {
    const changeListener = e => {
      const ff = e.target;
      if (ff.files) {
        // base64 encode the files to send as JSON
        for (const f of ff.files) {
          if (f.size > 37 * 1024 * 1024) {
            alert("This file is too large !");
            continue;
          }
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0)
              encoded += '='.repeat(4 - (encoded.length % 4));
            const ra = {
              fileName: f.name,
              size: f.size,
              content: encoded,
            };
            this.props.addAttachment(ra);
          }, false);
          reader.readAsDataURL(f);
        }
      }
    };
    return (
      <Form.Group controlId="attachments">
        <Form.Label>Attachments (37MB max)</Form.Label>
        <Form.File name="attachments" multiple onChange={changeListener}/>
        {this.props.attachments &&
          <p>{this.props.attachments.length} file(s) ready to be sent.</p>
        }
      </Form.Group>
    );
  }
}

AttachmentForm.propTypes = {
  attachments: PropTypes.array,
  addAttachment: PropTypes.func.isRequired,
};

export default AttachmentForm;
