import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { faCheck, faTrashAlt, faUserSecret, faPencilAlt } from
  '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { fetchCurrentUser, resetAppState } from '../app/actions';
import { fetchUsers } from '../users/actions';
import * as server from '../serverAPI.js';
import ConfirmModal from '../util/ConfirmModal';
import ErrorAlert from '../util/ErrorAlert';
import NavBar from '../util/NavBar';
import SortedTable from '../util/SortedTable';


class UserList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      userSelection: 'admins',
      error: null,
      userToRemove: null,
    };
  }
  
  async componentDidMount() {
    if (this.props.users == null) {
      try {
        await this.props.fetchUsers();
      } catch (error) {
        this.setState({ error: error.message });
      }
    }
    document.title = "Override Requests - Users";
  }
  
  async removeUser() {
    this.setState({ error: null, success: null });
    try {
      await server.removeUser(this.state.userToRemove);
      await this.props.fetchUsers();
    } catch (error) {
      this.setState({ error: "Remove user: " + error.message });
    } finally {
      this.setState({ userToRemove: null });
    }
  }
  
  async impersonate(userId) {
    this.setState({ error: null, success: null });
    try {
      await server.impersonate(userId);
      this.props.resetAppState();
      await this.props.fetchCurrentUser();
      this.props.history.push('/');
    } catch (error) {
      this.setState({ error: "Impersonate: " + error.message });
    }
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Users</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  unitsFieldValue(user) {
    return user.units.map(unit => unit.name).join(', ');
  }
  
  userRow(user) {
    return (
      <tr key={user.id}>
        <td className="code"><Link to={'/users/'+user.id}>{user.msuId}</Link></td>
        <td>{user.firstname}</td>
        <td>{user.lastname}</td>
        <td>{user.email}</td>
        <td>
          {user.superuser && <FontAwesomeIcon icon={faCheck} title="Yes"/>}
        </td>
        <td>{this.unitsFieldValue(user)}</td>
        <td>{user.lastLogin == null ? '' :
          (new Date(user.lastLogin)).toLocaleString()}</td>
        <td>
          <LinkContainer to={'/users/'+user.id}>
            <Button title="Edit" size="xs">
              <FontAwesomeIcon icon={faPencilAlt} title="Edit" />
            </Button>
          </LinkContainer>
          <Button title="Impersonate" variant="warning" size="xs"
              onClick={(e) => this.impersonate(user.id)}
              disabled={user.id === this.props.currentUser.id}>
            <FontAwesomeIcon icon={faUserSecret} title="Impersonate" />
          </Button>
          <Button title="Remove" variant="danger" size="xs"
              onClick={(e) => this.setState({ userToRemove: user.id })}
              disabled={user.id === this.props.currentUser.id}>
            <FontAwesomeIcon icon={faTrashAlt} title="Remove" />
          </Button>
        </td>
      </tr>
    );
  }
  
  handleChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }
  
  userSelection() {
    return (
      <Form className="mt-4">
        <Form.Group controlId="userSelection">
          <Form.Check name="userSelection" id="userSelection1" type="radio"
            label="Display only superusers and unit administrators" value="admins"
            checked={this.state.userSelection === 'admins'}
            onChange={e => this.handleChange(e)}/>
          <Form.Check name="userSelection" id="userSelection2" type="radio"
            label="Display all users" value="all"
            checked={this.state.userSelection === 'all'}
            onChange={e => this.handleChange(e)}/>
        </Form.Group>
      </Form>
    );
  }
  
  filterUsers(users) {
    if (this.state.userSelection === 'admins')
      return users.filter(u => u.superuser ||
        (u.units != null && u.units.length > 0));
    return users;
  }
  
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <NavBar items={ [
          { title: "Users", path: '/users' },
          { title: "Units", path: '/units' },
          { title: "Sessions", path: '/users/sessions' },
        ] }/>
        <h1>Users</h1>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        <ConfirmModal show={this.state.userToRemove !== null}
        whatToDo="remove this user" action="Remove"
        confirmed={() => this.removeUser()}
        cancelled={() => this.setState({ userToRemove: null })}/>
        {this.props.users != null &&
        <>
          <LinkContainer to="/users/create">
            <Button>Create a new user</Button>
          </LinkContainer>
          { this.userSelection() }
          <SortedTable
            fields={[
              ['msuId', 'MSU Id'],
              ['firstname', 'First name'],
              ['lastname', 'Last name'],
              ['email', 'Email'],
              ['superuser', 'Superuser'],
              ['units', 'Units'],
              ['lastLogin', 'Last login'],
              ['actions', ''],
            ]}
            records={this.filterUsers(this.props.users)}
            recordTableRow={(user) => this.userRow(user)}
            recordValueToSort={(record, field) => {
              if (field === 'units')
                return this.unitsFieldValue(record);
              return record[field];
            }}
          />
        </>
        }
      </>
    );
  }
  
}

UserList.propTypes = {
  // from redux connect:
  currentUser: PropTypes.object,
  users: PropTypes.array,
  fetchUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.app;
  const { users } = state.users;
  return { currentUser, users };
};
const mapDispatchToProps = { fetchCurrentUser, resetAppState, fetchUsers };

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
