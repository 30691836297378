import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { LinkContainer } from 'react-router-bootstrap';

import * as server from '../serverAPI.js';
import AttachmentForm from './AttachmentForm';
import AttachmentList from './AttachmentList';
import ErrorAlert from '../util/ErrorAlert';
import SuccessAlert from '../util/SuccessAlert';


class Message extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      message: {
        requestId: parseInt(this.props.match.params.requestId),
        from: this.props.currentUnit ?
          this.props.currentUnit.settings.studentEmailFrom : '',
        to: '',
        cc: this.props.currentUnit ?
          this.props.currentUnit.settings.studentEmailCc : '',
        bcc: this.props.currentUnit ?
          this.props.currentUnit.settings.studentEmailBcc : '',
        subject: '',
        body: '',
        attachments: null,
      },
      error: null,
      success: null,
      loaded: false,
    };
  }
  
  async componentDidMount() {
    try {
      const id = this.props.match.params.messageId;
      if (id) {
        document.title = "Override Requests - Message " + id;
        const message = await server.getMessage(id);
        for (const f in message) {
          if (message[f] == null)
            message[f] = '';
        }
        this.setState({ message, loaded: true });
      } else {
        document.title = "Override Requests - New Message";
        const requestId = this.props.match.params.requestId;
        const request = await server.getRequest(requestId);
        if (request == null) {
          this.setState({ error: `Request ${requestId} not found.` });
          return;
        }
        const newState = {
          message: {
            ...this.state.message,
            to: request.email,
          }
        };
        if (request.status === 'Granted') {
          newState.message.subject = this.props.currentUnit.settings.grantedEmailSubject;
          newState.message.body = this.props.currentUnit.settings.grantedEmailBody;
        } else if (request.status === 'Denied') {
          newState.message.subject = this.props.currentUnit.settings.deniedEmailSubject;
          newState.message.body = this.props.currentUnit.settings.deniedEmailBody;
        } else {
          newState.message.subject = this.props.currentUnit.settings.updateEmailSubject;
          newState.message.body = this.props.currentUnit.settings.updateEmailBody;
        }
        this.replaceVariables(newState.message, request);
        this.setState(newState);
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  handleMessageChange(event) {
    this.setState({
      message: {
        ...this.state.message,
        [event.target.name]: event.target.value
      }
    });
  }
  
  replaceVariables(message, request) {
    const otherVariables = {
      semester: request.semester.title,
      unit: this.props.currentUnit.name,
      adminFullName: this.props.currentUser.firstname + ' ' +
        this.props.currentUser.lastname,
      requestURL: window.location.protocol + '//' + window.location.host + '/requests/' + request.id,
    };
    for (const messageKey in message) {
      let v = message[messageKey];
      if (typeof v !== 'string')
        continue;
      for (const variableName in otherVariables) {
        v = v.split('[' + variableName + ']').join(otherVariables[variableName]);
      }
      for (const requestKey in request) {
        if (request[requestKey] == null)
          continue;
        v = v.split('[' + requestKey + ']').join(request[requestKey]);
      }
      message[messageKey] = v;
    }
  }
  
  async sendMessage() {
    this.setState({ error: null, success: null });
    try {
      const message = await server.sendMessage(this.state.message);
      this.setState({
        message,
        loaded: true,
        success: "The message was successfully sent.",
      });
      this.props.history.replace('/messages/' + message.id);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/requests">
          <Breadcrumb.Item>Requests</Breadcrumb.Item>
        </LinkContainer>
        {this.state.message.requestId ?
          <LinkContainer to={`/requests/${this.state.message.requestId}`}>
            <Breadcrumb.Item>Request</Breadcrumb.Item>
          </LinkContainer>
          :
          <Breadcrumb.Item>Request</Breadcrumb.Item>
        }
        {this.state.message.requestId ?
          <LinkContainer to={`/requests/${this.state.message.requestId}/messages`}>
            <Breadcrumb.Item>Messages</Breadcrumb.Item>
          </LinkContainer>
          :
          <Breadcrumb.Item>Messages</Breadcrumb.Item>
        }
        <Breadcrumb.Item active>Message</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  attachmentList() {
    return (
      <Form.Group>
        <Form.Label>Attachments</Form.Label>
        <AttachmentList list={this.state.message.attachments}/>
      </Form.Group>
    );
  }
  
  attachmentForm() {
    return (
      <AttachmentForm attachments={this.state.message.attachments}
        addAttachment={(attachment) => {
          let attachments = this.state.message.attachments;
          if (attachments == null)
            attachments = [];
          attachments.push(attachment);
          this.setState({
            message: {
              ...this.state.message,
              attachments,
            }
          });
        }}
      />
    );
  }
  
  messageForm() {
    if (this.props.match.params.messageId && !this.state.loaded)
      return null;
    const readOnly = !!this.props.match.params.messageId;
    return (
      <Form onSubmit={e => { e.preventDefault(); this.sendMessage(); } } className="form my-3">
        <Form.Group controlId="from">
          <Form.Label>From</Form.Label>
          <Form.Control name="from" type="email" maxLength="255"
            value={this.state.message.from} readOnly={readOnly}
            required onChange={e => this.handleMessageChange(e)}/>
        </Form.Group>
        <Form.Group controlId="to">
          <Form.Label>To</Form.Label>
          <Form.Control name="to" type="email" multiple maxLength="255"
            value={this.state.message.to} readOnly={readOnly}
            required onChange={e => this.handleMessageChange(e)}/>
        </Form.Group>
        <Form.Group controlId="cc">
          <Form.Label>CC</Form.Label>
          <Form.Control name="cc" type="email" multiple maxLength="255"
            value={this.state.message.cc} readOnly={readOnly}
            onChange={e => this.handleMessageChange(e)}/>
        </Form.Group>
        <Form.Group controlId="bcc">
          <Form.Label>BCC</Form.Label>
          <Form.Control name="bcc" type="email" multiple maxLength="255"
            value={this.state.message.bcc} readOnly={readOnly}
            onChange={e => this.handleMessageChange(e)}/>
        </Form.Group>
        <Form.Group controlId="subject">
          <Form.Label>Subject</Form.Label>
          <Form.Control name="subject" type="text" maxLength="255"
            value={this.state.message.subject} readOnly={readOnly}
            required onChange={e => this.handleMessageChange(e)}/>
        </Form.Group>
        {this.state.message.id && this.state.message.sentOn &&
          <Form.Group controlId="sentOn">
            <Form.Label>Sent On</Form.Label>
            <Form.Control name="sentOn" type="text" maxLength="255"
              value={(new Date(this.state.message.sentOn)).toLocaleDateString()}
              readOnly onChange={e => this.handleMessageChange(e)}/>
          </Form.Group>
        }
        <Form.Group controlId="body">
          <Form.Label>Body</Form.Label>
          <Form.Control name="body" as="textarea" rows="10"
            value={this.state.message.body} readOnly={readOnly}
            required onChange={e => this.handleMessageChange(e)}/>
        </Form.Group>
        {readOnly && this.state.message.attachments &&
            this.state.message.attachments.length > 0 &&
          this.attachmentList()
        }
        {!readOnly &&
          <>
            {this.attachmentForm()}
            <Form.Group>
              <Button variant="primary" type="submit" size="lg">
                Send
              </Button>
            </Form.Group>
          </>
        }
      </Form>
    );
  }
  
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <h1>Message</h1>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        <SuccessAlert message={this.state.success}
          onClose={() => this.setState({ success: null })}/>
        {this.messageForm()}
      </>
    );
  }
  
}

Message.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string,
      messageId: PropTypes.string,
    })
  }),
  // from redux connect:
  currentUser: PropTypes.object.isRequired,
  currentUnit: PropTypes.object, // only needed for new messages
};

const mapStateToProps = (state) => {
  const { currentUser, currentUnit } = state.app;
  return { currentUser, currentUnit };
};

export default connect(mapStateToProps)(Message);
