import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';

import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';


class NavBar extends Component {
  
  render() {
    // see https://github.com/react-bootstrap/react-router-bootstrap/issues/242#issuecomment-480330910
    return (
      <Nav as="ul">
        {this.props.items.map(item => {
          if (item.condition === false)
            return null;
          return (
            <Nav.Item key={item.path} as="li">
              <LinkContainer to={item.path} exact>
                <Nav.Link active={false}>
                  {item.title}
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
          );
        })}
      </Nav>
    );
  }
}

NavBar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    condition: PropTypes.bool,
  })).isRequired,
};

export default NavBar;
