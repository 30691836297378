import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import AttachmentList from './AttachmentList';
import AttachmentForm from './AttachmentForm';
import DateTimeInput from '../util/DateTimeInput';


class Request extends Component {
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.request != null && prevProps.request != null) {
      // update dynamic fields dependant on other fields
      if (this.props.request.requestReason !== prevProps.request.requestReason) {
        if (this.props.request.requestReason === 'Other') {
          document.getElementById('otherReason').focus();
        } else if (this.props.request.otherReason !== '') {
          this.props.setRequestField('otherReason', '');
        }
      }
      if (this.props.request.completePrereq !== prevProps.request.completePrereq) {
        if (this.props.request.completePrereq === false) {
          document.getElementById('prereqNeeded').focus();
        } else if (this.props.request.prereqNeeded !== '') {
          this.props.setRequestField('prereqNeeded', '');
        }
      }
      if (this.props.request.transferPrereq !== prevProps.request.transferPrereq) {
        if (this.props.request.transferPrereq === true) {
          document.getElementById('prereqTransfer').focus();
        } else if (this.props.request.prereqTransfer !== '') {
          this.props.setRequestField('prereqTransfer', '');
        }
      }
    }
  }
  
  handleRequestChange(event) {
    const target = event.target;
    let value;
    if (target.type === 'radio') {
      const sv = target.value;
      if (sv.toLowerCase() === 'true')
        value = true;
      else if (sv.toLowerCase() === 'false')
        value = false;
      else
        value = sv;
    } else if (target.type === 'checkbox')
      value = target.checked;
    else if (target.type === 'number')
      value = parseInt(target.value);
    else
      value = target.value;
    this.props.setRequestField(target.name, value);
  }
  
  formField(fieldName) {
    const { request, currentUnit, currentUser } = this.props;
    const disabled = request.id && (request.archived || !this.props.admin);
    // NOTE: readOnly would let users select text, but does not work with select/radio.
    // The alternative would be to simply output the values instead of form controls.
    switch(fieldName) {
      case 'classLevel': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>Class Level</Form.Label>
          <Form.Control name={fieldName} as="select" custom
              value={request[fieldName]} disabled={disabled}
              required onChange={e => this.handleRequestChange(e)}>
            <option value="">-- Select --</option>
            <option value="Freshman">Freshman</option>
            <option value="Sophomore">Sophomore</option>
            <option value="Junior">Junior</option>
            <option value="Senior">Senior</option>
            <option value="Lifelong Student">Lifelong Student</option>
            <option value="Graduate Student">Graduate Student</option>
          </Form.Control>
        </Form.Group>
      );
      case 'comments': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>Additional Comments</Form.Label>
          <Form.Control name={fieldName} as="textarea" rows="5"
            value={request[fieldName]} disabled={disabled}
            onChange={e => this.handleRequestChange(e)}/>
        </Form.Group>
      );
      case 'completePrereq': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label style={{ width:'100%' }}>Have you completed all prerequisites?</Form.Label>
          <Form.Check name={fieldName} id={fieldName+'1'} type="radio" custom
            label="Yes" inline value={true} required disabled={disabled}
            checked={request[fieldName] === true}
            onChange={e => this.handleRequestChange(e)}/>
          <Form.Check name={fieldName} id={fieldName+'2'} type="radio" custom
            label="No" inline value={false} required disabled={disabled}
            checked={request[fieldName] === false}
            onChange={e => this.handleRequestChange(e)}/>
        </Form.Group>
      );
      case 'courseCode': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>Course Code</Form.Label>
          <Form.Control name={fieldName} type="text" maxLength="4"
            value={request[fieldName]} disabled={disabled} pattern="\d+[A-Z]?"
            required onChange={e => this.handleRequestChange(e)}/>
        </Form.Group>
      );
      case 'courseSubject': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>Course Subject</Form.Label>
          <Form.Control name={fieldName} as="select" custom
              value={request[fieldName]} disabled={disabled}
              required onChange={e => this.handleRequestChange(e)}>
            {currentUnit.settings.formCourseSubjects.split(',').map((s, index) =>
              <option key={index} value={s}>{s}</option>
            )}
          </Form.Control>
        </Form.Group>
      );
      case 'credits': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>How many credits are you requesting an override for?</Form.Label>
          <Form.Control name={fieldName} type="number"
            value={request[fieldName]} disabled={disabled}
            required onChange={e => this.handleRequestChange(e)}/>
        </Form.Group>
      );
      case 'email': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>Email Address</Form.Label>
          <Form.Control name={fieldName} type="email" maxLength="100"
            value={request[fieldName]} disabled={disabled}
            required onChange={e => this.handleRequestChange(e)}/>
        </Form.Group>
      );
      case 'expectedGraduation': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>Expected Graduation<br/>
            (example: "Summer, 2020";
            for graduate and lifelong please enter "none 0000")
          </Form.Label>
          <Form.Control name={fieldName} type="text" maxLength="50"
            value={request[fieldName]} disabled={disabled}
            required onChange={e => this.handleRequestChange(e)}/>
        </Form.Group>
      );
      case 'firstname': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>First Name</Form.Label>
          <Form.Control name={fieldName} type="text" maxLength="100"
            value={request[fieldName]} disabled={disabled}
            required onChange={e => this.handleRequestChange(e)}/>
        </Form.Group>
      );
      case 'lastname': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>Last Name</Form.Label>
          <Form.Control name={fieldName} type="text" maxLength="100"
            value={request[fieldName]} disabled={disabled}
            required onChange={e => this.handleRequestChange(e)}/>
        </Form.Group>
      );
      case 'major': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>Major</Form.Label>
          <Form.Control name={fieldName} type="text" maxLength="100"
            value={request[fieldName]} disabled={disabled}
            required onChange={e => this.handleRequestChange(e)}/>
        </Form.Group>
      );
      case 'phone': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control name={fieldName} type="tel" maxLength="20"
            value={request[fieldName]} disabled={disabled}
            required onChange={e => this.handleRequestChange(e)}/>
        </Form.Group>
      );
      case 'pid': {
        if (!request.id || !this.props.admin)
          return null;
        return (
          <Form.Group key={fieldName} controlId={fieldName}>
            <Form.Label>PID</Form.Label>
            <Form.Control name={fieldName} type="text" maxLength="10"
              value={request[fieldName]} pattern="[A-Z]\d+"
              required onChange={e => this.handleRequestChange(e)}
              disabled={true}/>
          </Form.Group>
        );
      }
      case 'msuId': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>MSU NetID</Form.Label>
          <Form.Control name={fieldName} type="text" maxLength="30"
            value={request.id ? request.user.msuId : currentUser.msuId}
            disabled/>
        </Form.Group>
      );
      case 'otherReason': {
        if (request.requestReason !== 'Other')
          return null;
        return (
          <Form.Group key={fieldName} controlId={fieldName}>
            <Form.Label>Other Reason</Form.Label>
            <Form.Control name={fieldName} type="text" maxLength="255"
              value={request[fieldName]} disabled={disabled}
              onChange={e => this.handleRequestChange(e)}/>
          </Form.Group>
        );
      }
      case 'prereqNeeded': {
        if (request.completePrereq !== false)
          return (
            
            <Form.Group key={fieldName} controlId={fieldName}>
              <Form.Label>If no, what prerequisites are stillneeded?<br />
              (please list courses and separate with commas)</Form.Label>
              <Form.Control name={fieldName} type="text" maxLength="255"
              value={request[fieldName]} disabled={true}
              onChange={(e) => this.handleRequestChange(e)}/>
            </Form.Group>
          );
        return (
          <Form.Group key={fieldName} controlId={fieldName}>
            <Form.Label>If no, what prerequisites are still needed?<br/>
            (please list courses and separate with commas)</Form.Label>
            <Form.Control name={fieldName} type="text" maxLength="255"
              value={request[fieldName]} disabled={disabled}
              onChange={e => this.handleRequestChange(e)}/>
          </Form.Group>
        );
      }
      case 'prereqTransfer': {
        if (request.transferPrereq !== true)
          return (
            <Form.Group key={fieldName} controlId={fieldName}>
              <Form.Label>If yes, what prerequisites are in transfer?<br />
              (please list courses and separate withcommas)</Form.Label>
              <Form.Control name={fieldName} type="text" maxLength="255"
              value={request[fieldName]} disabled={true}
              onChange={(e) => this.handleRequestChange(e)}/>
            </Form.Group>
          );
        return (
          <Form.Group key={fieldName} controlId={fieldName}>
            <Form.Label>If yes, what prerequisites are in transfer?<br/>
            (please list courses and separate with commas)</Form.Label>
            <Form.Control name={fieldName} type="text" maxLength="255"
              value={request[fieldName]} disabled={disabled}
              onChange={e => this.handleRequestChange(e)}/>
          </Form.Group>
        );
      }
      case 'requestReason': {
        const reasons =
          this.props.currentUnit.settings.formReasons.split(',');
        // add the request reason value if it has been removed
        // from the list set by administrators
        if (request[fieldName] !== '' && request[fieldName] !== 'Other' &&
            !reasons.includes(request[fieldName]))
          reasons.push(request[fieldName]);
        return (
          <Form.Group key={fieldName} controlId={fieldName}>
            <Form.Label>Reason for Request</Form.Label>
            <Form.Control name={fieldName} as="select" custom
                value={request[fieldName]} disabled={disabled}
                required onChange={e => this.handleRequestChange(e)}>
              <option value="">-- Select --</option>
              {reasons.map(reason =>
                <option key={reason} value={reason}>{reason}</option>
              )}
              <option value="Other">Other reason</option>
            </Form.Control>
          </Form.Group>
        );
      }
      case 'sections': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>Section Number(s) (comma-separated, use 3 digits if possible)</Form.Label>
          <Form.Control name={fieldName} type="text" maxLength="255"
            value={request[fieldName]} pattern="(\d+|[A-Z])(,\s?(\d+|[A-Z]))*"
            required onChange={e => this.handleRequestChange(e)}
            disabled={disabled}/>
        </Form.Group>
      );
      case 'semesterId': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label>Course Semester</Form.Label>
          <Form.Control name={fieldName} as="select" custom
              value={request[fieldName]} disabled={disabled}
              required onChange={e => this.handleRequestChange(e)}>
            <option key="0" value="">--Select a semester--</option>
            {currentUnit.semesters.map((s) =>
              ((s.active && s.current) || (s.id === request[fieldName])) ?
                <option key={s.id} value={s.id}>{s.title}</option>
                : null
            )}
          </Form.Control>
        </Form.Group>
      );
      case 'transferPrereq': return (
        <Form.Group key={fieldName} controlId={fieldName}>
          <Form.Label style={{ width:'100%' }}>Will you be transferring any prerequisite?</Form.Label>
          <Form.Check name={fieldName} id={fieldName+'1'} type="radio" custom
            label="Yes" inline value={true} required disabled={disabled}
            checked={request[fieldName] === true}
            onChange={e => this.handleRequestChange(e)}/>
          <Form.Check name={fieldName} id={fieldName+'2'} type="radio" custom
            label="No" inline value={false} required disabled={disabled}
            checked={request[fieldName] === false}
            onChange={e => this.handleRequestChange(e)}/>
        </Form.Group>
      );
      case 'attachments': {
        if (request && request.id) {
          if (!request.attachments)
            return null;
          return (
            <Form.Group key={fieldName}>
              <Form.Label>Attachments</Form.Label>
              <AttachmentList
                match={ { params: {
                  requestId: request.id,
                } } }
                list={request.attachments}/>
            </Form.Group>
          );
        }
        return (
          <AttachmentForm key={fieldName} attachments={request.attachments}
            addAttachment={(attachment) => {
              let attachments = request.attachments;
              if (attachments == null)
                attachments = [];
              attachments.push(attachment);
              this.props.setRequestField('attachments', attachments);
            }}
          />
        );
      }
      default: return null;
    }
  }
  
  requestProcessing() {
    const request = this.props.request;
    const disabled = request.archived;
    return (
      <fieldset>
        <legend>Request Processing</legend>
        <Row>
          <Col sm="auto">
            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control name="status" as="select" custom
                value={request.status} disabled={disabled}
                required onChange={e => this.handleRequestChange(e)}>
                <option value="">--</option>
                <option value="Submitted">Submitted</option>
                <option value="Granted">Granted</option>
                <option value="Denied">Denied</option>
                <option value="Prereqs Verified">Prerequisites Verified</option>
                <option value="See Notes">See Notes</option>
                <option value="Cancelled">Cancelled by Student</option>
                <option value="Enrolled">Enrolled</option>
                <option value="SIS Entered">SIS Entered</option>
              </Form.Control>
            </Form.Group>
            <Form.Group key="approvedSections" controlId="approvedSections">
              <Form.Label>Approved Sections (comma-separated)</Form.Label>
              <Form.Control name="approvedSections" type="text" maxLength="255"
              value={request.approvedSections} disabled={disabled}
              onChange={e => this.handleRequestChange(e)}/>
            </Form.Group>
            <Form.Group key="adminNotes" controlId="adminNotes">
              <Form.Label>Notes</Form.Label>
              <Form.Control name="adminNotes" as="textarea" rows="5"
              value={request.adminNotes} disabled={disabled}
              onChange={e => this.handleRequestChange(e)}/>
            </Form.Group>
            <Form.Group key="dateSubmitted">
              <Form.Label htmlFor="dateSubmitted">Date Submitted</Form.Label>
              <DateTimeInput name="dateSubmitted"
              value={request.dateSubmitted} disabled
              onChange={e => this.handleRequestChange(e)}/>
            </Form.Group>
            <Form.Group key="dateEntered">
              <Form.Label htmlFor="dateEntered">Date Entered in SIS</Form.Label>
              <Button variant="secondary" size="sm" className="ml-2" onClick={(e) =>
                this.props.setRequestField('dateEntered',
                  (new Date()).toISOString())
              }>Set to now</Button>
              <DateTimeInput name="dateEntered"
              value={request.dateEntered} disabled={disabled}
              onChange={e => this.handleRequestChange(e)}/>
            </Form.Group>
            <Form.Group key="dateEnrolled">
              <Form.Label htmlFor="dateEnrolled">Date Enrolled</Form.Label>
              <Button variant="secondary" size="sm" className="ml-2" onClick={(e) =>
                this.props.setRequestField('dateEnrolled',
                  (new Date()).toISOString())
              }>Set to now</Button>
              <DateTimeInput name="dateEnrolled"
              value={request.dateEnrolled} disabled={disabled}
              onChange={e => this.handleRequestChange(e)}/>
            </Form.Group>
            <Form.Group key="dateConfirmationSent">
              <Form.Label htmlFor="dateConfirmationSent">Date Confirmation Sent</Form.Label>
              <Button variant="secondary" size="sm" className="ml-2" onClick={(e) =>
                this.props.setRequestField('dateConfirmationSent',
                  (new Date()).toISOString())
              }>Set to now</Button>
              <DateTimeInput name="dateConfirmationSent"
              value={request.dateConfirmationSent} disabled={disabled}
              onChange={e => this.handleRequestChange(e)}/>
            </Form.Group>
          </Col>
        </Row>
      </fieldset>
    );
  }
  
  fieldSet(title, fields) {
    return (
      <fieldset>
        <legend>{title}</legend>
        <Row>
          <Col sm="auto">
            { fields.map(fieldName => this.formField(fieldName)) }
          </Col>
        </Row>
      </fieldset>
    );
  }
  
  formFields() {
    const displayedFields =
      this.props.currentUnit.settings.formDisplayedFields.split(',');
    const requestForFields = ['courseSubject', 'courseCode', 'sections',
      'semesterId'];
    const administrativeFields = ['msuId', 'pid', 'firstname', 'lastname',
      'email', 'phone', 'major', 'classLevel', 'expectedGraduation'];
    const requestDetailsFields = ['credits', 'requestReason', 'otherReason',
      'completePrereq', 'prereqNeeded', 'transferPrereq', 'prereqTransfer',
      'comments', 'attachments'];
    return (
      <>
        {this.props.admin && this.props.request.id &&
          this.requestProcessing()
        }
        {this.fieldSet("Request For", displayedFields.filter(
          f => requestForFields.includes(f)))}
        {this.fieldSet("Administrative Information", displayedFields.filter(
          f => administrativeFields.includes(f)))}
        {this.fieldSet("Request Details", displayedFields.filter(
          f => requestDetailsFields.includes(f)))}
      </>
    );
  }
  
  form() {
    return (
      <Form onSubmit={e => { e.preventDefault(); this.props.saveRequest(); } }>
        { this.formFields() }
        {(!this.props.request.id || (!this.props.request.archived && this.props.admin)) &&
        <Form.Group>
          <Button variant="primary" type="submit" size="lg">
            {this.props.request.id ? "Save" : "Submit"}
          </Button>
        </Form.Group>
        }
      </Form>
    );
  }
  
  render() {
    return (
      <>
        { this.props.request.id ?
          <section className="border">
            <h2>Request</h2>
            {this.form()}
          </section>
          :
          this.form()
        }
      </>
    );
  }
  
}

Request.propTypes = {
  request: PropTypes.object.isRequired,
  currentUnit: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  admin: PropTypes.bool.isRequired,
  setRequestField: PropTypes.func.isRequired,
  saveRequest: PropTypes.func.isRequired,
};

export default Request;
