import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';

import { fetchCurrentUnit } from './actions';
import { fetchUnits } from '../units/actions';
import ErrorAlert from '../util/ErrorAlert';
import NavBar from '../util/NavBar';
import RequestListContainer from '../requests/RequestListContainer';
import UnitNav from '../units/UnitNav';


class Home extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedUnitId: this.props.currentUnit ? this.props.currentUnit.id : '',
    };
  }
  
  componentDidMount() {
    document.title = "Override Requests";
    if (this.props.units == null)
      this.loadUnits();
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedUnitId !== prevState.selectedUnitId)
      this.loadSelectedUnit();
    if (this.props.currentUnit !== prevProps.currentUnit &&
        this.props.currentUnit != null && this.state.selectedUnitId === '')
      this.setState({ selectedUnitId: this.props.currentUnit.id });
    else if (prevProps.units == null && this.props.units != null &&
        this.state.selectedUnitId === '' && this.props.units.length === 1)
      this.setState({ selectedUnitId: this.props.units[0].id });
  }
  
  handleChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <Breadcrumb.Item active>Home</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  superuserHome() {
    return (
      <NavBar items={ [
        { title: "Users", path: '/users' },
        { title: "Units", path: '/units' },
        { title: "Sessions", path: '/users/sessions' },
      ] }/>
    );
  }
  
  async loadSelectedUnit() {
    try {
      await this.props.fetchCurrentUnit(
        this.state.selectedUnitId === '' ? null : this.state.selectedUnitId);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  async loadUnits() {
    try {
      await this.props.fetchUnits();
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  unitSelection() {
    if (this.props.units == null)
      return null;
    if (this.state.selectedUnitId == null)
      return null;
    return (
      <Form inline className="mt-2">
        <Form.Group controlId="selectedUnitId">
          <Form.Label className="mr-1">Unit:</Form.Label>
          <Form.Control name="selectedUnitId" as="select" custom
          value={this.state.selectedUnitId}
          onChange={e => this.handleChange(e)}>
            <option key="0" value="">--Select a unit--</option>
            {this.props.units.map((u) =>
              <option key={u.id} value={u.id}>{u.name}</option>
            )}
          </Form.Control>
        </Form.Group>
      </Form>
    );
  }
  
  requestList() {
    return (
      <RequestListContainer/>
    );
  }
  
  render() {
    const role = this.props.currentRole;
    const admin = role === 'superuser' || role === 'admin';
    return (
      <>
        {this.breadcrumbs()}
        <h1>Override Requests</h1>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        {this.props.currentUser && this.props.currentUser.superuser &&
        this.superuserHome()}
        {this.props.currentUser &&
        this.unitSelection()}
        {this.props.currentUser && this.props.currentUnit && admin &&
        <UnitNav/>
        }
        {this.props.currentUser && this.props.currentUnit &&
        <>
          { admin ?
            <section className="border">
              <h2>Requests</h2>
              {this.requestList()}
            </section>
            :
            this.requestList()
          }
        </>
        }
      </>
    );
  }
  
}

Home.propTypes = {
  // from redux connect:
  currentUser: PropTypes.object,
  currentUnit: PropTypes.object,
  currentRole: PropTypes.string,
  units: PropTypes.array,
  fetchCurrentUnit: PropTypes.func.isRequired,
  fetchUnits: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser, currentUnit, currentRole } = state.app;
  const { units } = state.units;
  return { currentUser, currentUnit, currentRole, units };
};
const mapDispatchToProps = { fetchCurrentUnit, fetchUnits };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
