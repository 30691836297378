import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { LinkContainer } from 'react-router-bootstrap';

import { fetchCurrentUnit } from '../app/actions';
import * as server from '../serverAPI.js';
import ErrorAlert from '../util/ErrorAlert';
import SuccessAlert from '../util/SuccessAlert';
import UnitNav from './UnitNav';


class Semester extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      semester: {
        id: this.props.match.params.semesterId ?
          parseInt(this.props.match.params.semesterId) : null,
        unitId: this.props.match.params.unitId ?
          parseInt(this.props.match.params.unitId) : null,
        title: '',
        current: false,
        active: true,
      },
      error: null,
      success: null,
      loaded: false,
    };
  }
  
  async componentDidMount() {
    try {
      const id = this.state.semester.id;
      if (id != null) {
        const semester = await server.getSemester(id);
        this.setState({ semester, loaded: true });
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
    if (this.state.semester.id == null)
      document.title = "New Semester";
    else
      document.title = "Semester " + this.state.semester.id;
  }
  
  handleSemesterChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked :
      (target.type === 'number' ? parseInt(target.value) : target.value);
    this.setState({
      semester: {
        ...this.state.semester,
        [target.name]: value
      }
    });
  }
  
  async saveSemester() {
    this.setState({ error: null, success: null });
    try {
      let semester = null;
      if (this.state.semester.id) {
        await server.updateSemester(this.state.semester);
      } else {
        semester = await server.createSemester(this.state.semester);
        this.setState({ semester, loaded: true });
        this.props.history.replace('/units/' + this.props.match.params.unitId +
          '/semesters/' + this.state.semester.id);
      }
      this.setState({ success: "The semester was successfully saved." });
      document.title = "Semester " + this.state.semester.id;
      // reload app currentUnit with new settings:
      this.props.fetchCurrentUnit(this.props.match.params.unitId);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        {this.props.currentRole === 'superuser' &&
          <>
            <LinkContainer to="/units">
              <Breadcrumb.Item>Units</Breadcrumb.Item>
            </LinkContainer>
            <LinkContainer to={'/units/' + this.props.match.params.unitId}>
              <Breadcrumb.Item>Unit</Breadcrumb.Item>
            </LinkContainer>
          </>
        }
        <LinkContainer to={'/units/' + this.props.match.params.unitId + '/semesters'}>
          <Breadcrumb.Item>Semesters</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Semester</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  semesterForm() {
    if (this.state.semester.id != null && !this.state.loaded)
      return null;
    return (
      <Form onSubmit={e => { e.preventDefault(); this.saveSemester(); } }>
        <fieldset>
          <legend>Semester Configuration</legend>
        
          <Form.Group as={Row} controlId="title">
            <div className="col-md-4">
              <Form.Label>Title</Form.Label>
              <Form.Control name="title" type="text" maxLength="50"
            value={this.state.semester.title} style={{ width:'auto' }}
            required onChange={e => this.handleSemesterChange(e)}/>
            </div>
          </Form.Group>
          <Form.Group controlId="active">
            <Form.Check name="active" type="checkbox" custom
            value={this.state.semester.active}
            onChange={e => this.handleSemesterChange(e)}
            checked={this.state.semester.active}
            label="Active (display in administrator)"/>
          </Form.Group>
          <Form.Group controlId="current">
            <Form.Check name="current" type="checkbox" custom
            value={this.state.semester.current}
            onChange={e => this.handleSemesterChange(e)}
            checked={this.state.semester.current}
            label="Current (display on public form)"/>
          </Form.Group>
          <Form.Group>
            <Button variant="primary" type="submit">
            Save
            </Button>
          </Form.Group>
        </fieldset>
      </Form>
    );
  }
  /*Removed the logic for the H1 display, didn't make sense to show a random ID that the user won't care about. if anything, show the semester added*/
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <UnitNav/>
        <h1>{this.state.semester.id ? "Edit Semester" : "New Semester"}</h1>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        <SuccessAlert message={this.state.success}
          onClose={() => this.setState({ success: null })}/>
        {this.semesterForm()}
      </>
    );
  }
  
}

Semester.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string.isRequired,
      semesterId: PropTypes.string, // this initial semester id can be undefined for a new semester
    })
  }),
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  // from redux connect:
  currentRole: PropTypes.string.isRequired,
  fetchCurrentUnit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentRole } = state.app;
  return { currentRole };
};
const mapDispatchToProps = { fetchCurrentUnit };

export default connect(mapStateToProps, mapDispatchToProps)(Semester);
