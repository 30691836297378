import React, { PureComponent } from 'react';

import Form from 'react-bootstrap/Form';

import PropTypes from 'prop-types';


/**
 * Date/time picker, using ISO 8601
 */
class DateTimeInput extends PureComponent {
  
  constructor(props) {
    super(props);
    this.state = this.stateFromValue(this.props.value);
  }
  
  stateFromValue(value) {
    if (this.props.value !== null && this.props.value !== '') {
      const date = new Date(this.props.value);
      return {
        month: date.getMonth() + 1,
        day: date.getDate(),
        year: date.getFullYear(),
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
      };
    }
    return {
      month: '',
      day: '',
      year: '',
      hours: '',
      minutes: '',
      seconds: '',
    };
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      this.setState(this.stateFromValue(this.props.value));
    }
  }
  
  handleChange(e) {
    const target = e.target;
    const newState = {
      ...this.state,
      [target.name]: target.value === '' ? '' : parseInt(target.value),
    };
    this.setState(newState);
    let result = '';
    if (newState.month !== '' && newState.day !== '' &&
        newState.year !== '' && newState.hours !== '' &&
        newState.minutes !== '' && newState.seconds !== '') {
      const date = new Date(newState.year, newState.month - 1,
        newState.day, newState.hours, newState.minutes, newState.seconds);
      result = date.toISOString();
    }
    this.props.onChange({
      target: {
        name: this.props.name,
        value: result,
      }
    });
  }
  
  render() {
    const years = [];
    for (let i=2020; i<=2030; i++)
      years.push(i);
    const days = [];
    for (let i=1; i<=31; i++)
      days.push(i);
    const hours = [];
    for (let i=0; i<=23; i++)
      hours.push(i);
    const minutes = [];
    for (let i=0; i<=59; i++)
      minutes.push(i);
    const seconds = [];
    for (let i=0; i<=59; i++)
      seconds.push(i);
    const somethingDefined = this.state.month !== '' || this.state.day !== '' ||
      this.state.year !== '' || this.state.hours !== '' ||
      this.state.minutes !== '' || this.state.seconds !== '';
    return (
      <div id={this.props.name} role="group" className="form-inline">
        <Form.Control name="month" as="select" custom
            value={this.state.month} disabled={this.props.disabled}
            required={this.props.required} size="sm"
            isInvalid={somethingDefined && !this.state.month}
            onChange={e => this.handleChange(e)}
            aria-label={this.props.name + " month"}>
          <option value="">Month</option>
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </Form.Control>
        <Form.Control name="day" as="select" custom
            value={this.state.day} disabled={this.props.disabled}
            required={this.props.required} size="sm"
            isInvalid={somethingDefined && this.state.day === ''}
            onChange={e => this.handleChange(e)}
            aria-label={this.props.name + " day"}>
          <option value="">DD</option>
          {days.map(d => <option key={d}>{d}</option>)}
        </Form.Control>
        <Form.Control name="year" as="select" custom
            value={this.state.year} disabled={this.props.disabled}
            required={this.props.required} size="sm"
            isInvalid={somethingDefined && this.state.year === ''}
            onChange={e => this.handleChange(e)}
            aria-label={this.props.name + " year"}>
          <option value="">YYYY</option>
          {years.map(y => <option key={y}>{y}</option>)}
        </Form.Control>
        <Form.Control name="hours" as="select" custom
            value={this.state.hours} disabled={this.props.disabled}
            required={this.props.required} size="sm"
            isInvalid={somethingDefined && this.state.hours === ''}
            onChange={e => this.handleChange(e)}
            aria-label={this.props.name + " hours"}>
          <option value="">HH</option>
          {hours.map(h => <option key={h}>{h}</option>)}
        </Form.Control>
        <Form.Control name="minutes" as="select" custom
            value={this.state.minutes} disabled={this.props.disabled}
            required={this.props.required} size="sm"
            isInvalid={somethingDefined && this.state.minutes === ''}
            onChange={e => this.handleChange(e)}
            aria-label={this.props.name + " minutes"}>
          <option value="">MM</option>
          {minutes.map(m => <option key={m}>{m}</option>)}
        </Form.Control>
        <Form.Control name="seconds" as="select" custom
            value={this.state.seconds} disabled={this.props.disabled}
            required={this.props.required} size="sm"
            isInvalid={somethingDefined && this.state.seconds === ''}
            onChange={e => this.handleChange(e)}
            aria-label={this.props.name + " seconds"}>
          <option value="">SS</option>
          {seconds.map(s => <option key={s}>{s}</option>)}
        </Form.Control>
      </div>
    );
  }
}

DateTimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DateTimeInput;
