import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { LinkContainer } from 'react-router-bootstrap';

import { fetchCurrentUnit } from '../app/actions';
import * as server from '../serverAPI.js';
import ErrorAlert from '../util/ErrorAlert';
import MessageList from './MessageList';
import RequestForm from './RequestForm';
import SuccessAlert from '../util/SuccessAlert';
import UpdateList from './UpdateList';


class RequestContainer extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      request: null,
      error: null,
      success: null,
      admin: false,
      title: "Override Request",
    };
    if (this.props.currentUnit && !(this.props.match && this.props.match.params.requestId)) {
      const request = {};
      const displayedFields =
        this.props.currentUnit.settings.formDisplayedFields.split(',');
      for (const f of displayedFields) {
        if (f !== 'msuId')
          request[f] = '';
      }
      request.attachments = null;
      for (const f of ['firstname', 'lastname', 'pid', 'email', 'phone',
        'classLevel']) {
        if (this.props.currentUser[f])
          request[f] = this.props.currentUser[f];
      }
      if (this.props.currentUser.primaryMajor)
        request.major = this.props.currentUser.primaryMajor;
      // A preselected semester is undesirable when more than 1 is possible
      const possibleSemesterIds = [];
      for (const s of this.props.currentUnit.semesters) {
        if (s.active && s.current)
          possibleSemesterIds.push(s.id);
      }
      if (possibleSemesterIds.length === 1)
        request.semesterId = possibleSemesterIds[0];
      if (this.props.currentUnit.settings.formCourseSubjects) {
        request.courseSubject =
          this.props.currentUnit.settings.formCourseSubjects.split(',')[0];
      }
      this.state.request = request;
    }
    if (!(this.props.match && this.props.match.params.requestId))
      this.state.title = "New Override / Special Permissions Request";
    const role = this.props.currentRole;
    this.state.admin = role === 'superuser' || role === 'admin';
  }
  
  async componentDidMount() {
    try {
      const id = this.props.match ? this.props.match.params.requestId : null;
      let title = null;
      if (id != null) {
        const request = await server.getRequest(id);
        for (const f in request)
          if (request[f] == null)
            request[f] = '';
        const newState = { request };
        if (this.props.currentUnit == null)
          this.props.fetchCurrentUnit(request.semester.unitId);
        if (this.props.currentUnit &&
            this.props.currentUnit.id !== request.semester.unitId) {
          newState.error = "This request is not for the selected unit.";
          newState.success = null;
        }
        title = "Request submitted on " +
          (new Date(request.dateSubmitted)).toLocaleDateString();
        newState.title = title;
        this.setState(newState);
      } else {
        if (this.props.currentUnit == null)
          this.setState({ error: "No unit selected.", success: null });
        title = "New Override / Special Permissions Request";
      }
      document.title = title;
    } catch (error) {
      this.setState({ error: error.message, success: null });
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentRole !== prevProps.currentRole) {
      const role = this.props.currentRole;
      this.setState({ admin: role === 'superuser' || role === 'admin' });
    }
  }
  
  async saveRequest() {
    this.setState({ error: null, success: null });
    try {
      let request = Object.assign({}, this.state.request);
      for (const f in request)
        if (request[f] === '')
          request[f] = null;
      delete request.messages;
      delete request.updates;
      delete request.semester;
      if (request.id) {
        await server.updateRequest(request);
        this.setState({ success: "The request was successfully saved.", error: null });
      } else {
        request = await server.createRequest(request);
        for (const f in request)
          if (request[f] == null)
            request[f] = '';
        this.setState({ request });
        this.setState({ success: this.props.currentUnit.settings.formSubmissionSuccess,
          error: null });
        this.props.history.replace('/requests/' + request.id);
      }
      if (this.state.admin) {
        // Reload the request (the update list needs to be updated)
        request = await server.getRequest(request.id);
        if (request == null) {
          this.setState({ error: `Request ${request.id} not found.`, success: null });
          return;
        }
        for (const f in request)
          if (request[f] == null)
            request[f] = '';
        this.setState({ request });
      }
      document.title = "Override Request " + this.state.request.id;
    } catch (error) {
      this.setState({ error: this.state.request.id ? error.message :
        this.props.currentUnit.settings.formSubmissionError +
        "(" + error.message + ")"});
    }
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/requests">
          <Breadcrumb.Item>Requests</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Request</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  messages() {
    return (
      <section className="border">
        <h2>Messages</h2>
        <div className="ml-3">
          <MessageList requestId={this.props.match.params.requestId}
            request={this.state.request} />
        </div>
      </section>
    );
  }
  
  updates() {
    return (
      <section className="border">
        <h2>Updates</h2>
        <div className="ml-3">
          <UpdateList
            match={ { params: { requestId: this.props.match.params.requestId } } }
            request={this.state.request} />
        </div>
      </section>
    );
  }
  
  requestHeader() {
    return (
      <>
        {!this.state.request.id && this.props.currentUnit.settings.formIntro &&
          <section id="formIntro" dangerouslySetInnerHTML={{__html:
            this.props.currentUnit.settings.formIntro}}/>
        }
        {this.state.request.id && !this.state.admin &&
          <section>The request has been sent with the following data.</section>
        }
        {this.props.match && this.props.match.params.requestId &&
          <Container fluid className="px-0"><Row>
            <Col xl="8" md="12">{ this.messages() }</Col>
            {this.state.admin &&
              <Col xl="4" md="12">{ this.updates() }</Col> }
          </Row></Container>
        }
      </>
    );
  }
  
  setRequestField(name, value) {
    this.setState({
      request: {
        ...this.state.request,
        [name]: value,
      }
    });
  }
  
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <h1>{this.state.title}</h1>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        <SuccessAlert message={this.state.success}
          onClose={() => this.setState({ success: null })}/>
        {this.props.currentUnit && this.state.request &&
          <>
            {this.requestHeader()}
            <RequestForm request={this.state.request}
              currentUnit={this.props.currentUnit}
              currentUser={this.props.currentUser}
              admin={this.state.admin}
              setRequestField={(name, value) => this.setRequestField(name, value)}
              saveRequest={() => this.saveRequest()}
            />
          </>
        }
      </>
    );
  }
  
}

RequestContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string,
    })
  }),
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  // from redux connect:
  currentUser: PropTypes.object,
  currentUnit: PropTypes.object,
  currentRole: PropTypes.string,
  fetchCurrentUnit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser, currentUnit, currentRole } = state.app;
  return { currentUser, currentUnit, currentRole };
};
const mapDispatchToProps = { fetchCurrentUnit };

export default connect(mapStateToProps, mapDispatchToProps)(RequestContainer);
