import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setSavedSemesterId, setSavedRequestType } from './actions';
import * as server from '../serverAPI.js';
import ConfirmModal from '../util/ConfirmModal';
import ErrorAlert from '../util/ErrorAlert';
import RequestList from './RequestList';


class RequestListContainer extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      requests: null,
      semesterId: '',
      requestType: this.props.savedRequestType,
      requestToCancel: null,
      requestToRemove: null,
    };
  }
  
  selectASemester() {
    const semesters = this.props.currentUnit.semesters;
    if (semesters == null || semesters.length === 0) {
      this.setState({ requests: null });
      return;
    }
    let semesterId = '';
    if (this.props.savedSemesterId != null) {
      const semester = semesters.find(s => s.id === this.props.savedSemesterId);
      if (semester)
        semesterId = semester.id;
    }
    if (semesterId === '') {
      const semester = semesters.find(s => s.active && s.current);
      if (semester)
        semesterId = semester.id;
    }
    if (typeof semesterId === 'number')
      semesterId = '' + semesterId;
    this.getRequestsAndChangeState({ semesterId });
  }
  
  componentDidMount() {
    this.selectASemester();
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentUnit.id !== prevProps.currentUnit.id)
      this.selectASemester();
  }
  
  async getRequests(semesterId, requestType) {
    if (semesterId == null || semesterId === '')
      return null;
    try {
      const requests = await server.getSemesterRequests(
        semesterId, requestType);
      return requests;
    } catch (error) {
      this.setState({ error: error.message });
      return null;
    }
  }
  
  async getRequestsAndChangeState(changes) {
    // wait until requests are returned to change the state
    // (otherwise render would use the new parameters with the old requests)
    let semesterId = this.state.semesterId;
    if (changes.semesterId !== undefined)
      semesterId = changes.semesterId;
    if (typeof semesterId === 'string') {
      semesterId = parseInt(semesterId);
      if (isNaN(semesterId))
        semesterId = null;
    }
    let requestType = this.state.requestType;
    if (changes.requestType !== undefined)
      requestType = changes.requestType;
    const requests = await this.getRequests(semesterId, requestType);
    if (semesterId !== this.props.savedSemesterId)
      this.props.setSavedSemesterId(semesterId);
    if (requestType !== this.props.savedRequestType)
      this.props.setSavedRequestType(requestType);
    this.setState({
      ...changes,
      requests,
    });
  }
  
  async setRequests() {
    const requests = await this.getRequests(this.state.semesterId,
      this.state.requestType);
    this.setState({ requests });
  }
  
  async archiveRequest(requestId) {
    this.setState({ error: null, success: null });
    try {
      const request = await server.getRequest(requestId);
      delete request.messages;
      delete request.attachments;
      delete request.updates;
      delete request.semester;
      request.archived = true;
      await server.updateRequest(request);
      this.setRequests();
    } catch(error) {
      this.setState({ error: "Archive request: " + error.message });
    }
  }
  
  async restoreRequest(requestId) {
    this.setState({ error: null, success: null });
    try {
      const request = await server.getRequest(requestId);
      delete request.messages;
      delete request.attachments;
      delete request.updates;
      delete request.semester;
      request.archived = false;
      await server.updateRequest(request);
      this.setRequests();
    } catch(error) {
      this.setState({ error: "Restore request: " + error.message });
    }
  }
  
  removeRequest(requestId) {
    this.setState({ requestToRemove: requestId });
  }
  
  async doRemoveRequest() {
    this.setState({ error: null, success: null });
    try {
      await server.removeRequest(this.state.requestToRemove);
      this.setRequests();
    } catch(error) {
      this.setState({ error: "Remove request: " + error.message });
    } finally {
      this.setState({ requestToRemove: null });
    }
  }
  
  cancelRequest(requestId) {
    this.setState({ requestToCancel: requestId });
  }
  
  async doCancelRequest() {
    this.setState({ error: null, success: null });
    try {
      await server.cancelRequest(this.state.requestToCancel);
      this.setRequests();
    } catch(error) {
      this.setState({ error: "Remove request: " + error.message });
    } finally {
      this.setState({ requestToCancel: null });
    }
  }
  
  changeSelection(name, value) {
    this.getRequestsAndChangeState({
      [name]: value
    });
  }
  
  render() {
    return (
      <>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        <ConfirmModal show={this.state.requestToCancel !== null}
          whatToDo="cancel this request" action="Cancel the request"
          confirmed={() => this.doCancelRequest()}
          cancelled={() => this.setState({ requestToCancel: null })}/>
        <ConfirmModal show={this.state.requestToRemove !== null}
          whatToDo="remove this request" action="Remove"
          confirmed={() => this.doRemoveRequest()}
          cancelled={() => this.setState({ requestToRemove: null })}/>
        <RequestList
          currentRole={this.props.currentRole}
          currentUnit={this.props.currentUnit}
          semesterId={this.state.semesterId}
          requestType={this.state.requestType}
          changeSelection={(name, value) => this.changeSelection(name, value)}
          requests={this.state.requests}
          archiveRequest={requestId => this.archiveRequest(requestId)}
          restoreRequest={requestId => this.restoreRequest(requestId)}
          removeRequest={requestId => this.removeRequest(requestId)}
          cancelRequest={requestId => this.cancelRequest(requestId)}
        />
      </>
    );
  }
  
}

RequestListContainer.propTypes = {
  // from redux connect:
  currentUnit: PropTypes.object.isRequired,
  currentRole: PropTypes.string,
  savedSemesterId: PropTypes.number,
  savedRequestType: PropTypes.string,
  setSavedSemesterId: PropTypes.func.isRequired,
  setSavedRequestType: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUnit, currentRole } = state.app;
  const { savedSemesterId, savedRequestType } = state.requests;
  return { currentUnit, currentRole, savedSemesterId, savedRequestType };
};
const mapDispatchToProps = { setSavedSemesterId, setSavedRequestType };

export default connect(mapStateToProps, mapDispatchToProps)(RequestListContainer);
