import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { LinkContainer } from 'react-router-bootstrap';

import MessageList from './MessageList';


class MessageListPage extends Component {
  
  componentDidMount() {
    document.title = "Override Requests - Messages";
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/requests">
          <Breadcrumb.Item>Requests</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to={'/requests/' + this.props.match.params.requestId}>
          <Breadcrumb.Item>Request</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Messages</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <h1>Messages</h1>
        <MessageList requestId={this.props.match.params.requestId}/>
      </>
    );
  }
  
}

MessageListPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string.isRequired,
    })
  }),
};

export default MessageListPage;
