import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { setCurrentUnit } from '../app/actions';
import { setUnits } from '../units/actions';
import { fetchUsers } from '../users/actions';
import * as server from '../serverAPI.js';
import ErrorAlert from '../util/ErrorAlert';
import SuccessAlert from '../util/SuccessAlert';
import UnitNav from './UnitNav';


class Unit extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      unit: {
        id: this.props.match.params.unitId,
        name: '',
        active: true,
      },
      error: null,
      success: null,
      usersToAdd: null,
      selectedUserId: null,
      role: 'admin',
      roles: null,
      loaded: false,
    };
  }
  
  async componentDidMount() {
    const id = this.state.unit.id;
    try {
      if (id != null) {
        const unit = await server.getUnit(id);
        this.setState({ unit, loaded: true });
        if (!this.props.currentUnit || this.props.currentUnit.id !== unit.id)
          this.props.setCurrentUnit(unit);
      } else {
        if (this.props.currentUnit)
          this.props.setCurrentUnit(null);
      }
      const roles = await server.getRoles();
      this.setState({ roles });
      if (this.props.users == null)
        await this.props.fetchUsers();
      if (this.state.unit.users != null)
        this.updateUsersToAdd();
    } catch (error) {
      this.setState({ error: error.message });
    }
    if (id == null)
      document.title = "Override Requests - New Unit";
    else
      document.title = "Override Requests - " + (this.state.unit.name ?
        "Unit: " + this.state.unit.name: "Unit");
    if (this.state.unit.name === '') {
      const nameInput = document.getElementById('name');
      if (nameInput != null)
        nameInput.focus();
    }
  }
  
  updateUsersToAdd() {
    const usersToAdd = this.props.users
      .filter((u) => !this.state.unit.users.some((u2) => u2.id === u.id))
      .sort((u1, u2) => u1.msuId.localeCompare(u2.msuId));
    this.setState({
      usersToAdd,
      selectedUserId: usersToAdd.length === 0 ? '' : usersToAdd[0].id,
    });
  }
  
  handleUnitChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked :
      (target.type === 'number' ? parseInt(target.value) : target.value);
    this.setState({
      unit: {
        ...this.state.unit,
        [target.name]: value
      }
    });
  }
  
  handleChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }
  
  async removeUnit() {
    this.setState({ error: null, success: null });
    this.props.setUnits(null);
    try {
      await server.removeUnit(this.state.unit.id);
      this.props.history.push('/units/');
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  async saveUnit() {
    this.setState({ error: null, success: null });
    this.props.setUnits(null);
    try {
      if (this.state.unit.id) {
        const unit = { ...this.state.unit };
        delete unit.settings;
        delete unit.semesters;
        delete unit.users;
        await server.updateUnit(unit);
      } else {
        const unit = await server.createUnit(this.state.unit);
        unit.users = [];
        this.setState({ unit, loaded: true });
        this.props.history.replace('/units/' + this.state.unit.id);
        if (unit.users != null)
          this.updateUsersToAdd();
        this.props.setCurrentUnit(unit);
      }
      this.setState({ success: "The unit was successfully saved." });
      document.title = "Override Requests - " + (this.state.unit.name ?
        "Unit: " + this.state.unit.name: "Unit");
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  async removeUser(userId) {
    this.setState({ error: null, success: null });
    try {
      await server.removeUnitUser(this.state.unit.id, userId);
      const unit = await server.getUnit(this.state.unit.id);
      this.setState({ unit });
      this.updateUsersToAdd();
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/units">
          <Breadcrumb.Item>Units</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Unit</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  unitUserList() {
    if (this.state.unit.users == null)
      return null;
    return this.state.unit.users.map(user => (
      <tr key={user.id}>
        <td className="code"><Link to={'/users/'+user.id}>{user.msuId}</Link></td>
        <td>{user.firstname}</td>
        <td>{user.lastname}</td>
        <td>{user.userUnit.role}</td>
        <td>
          <Button title="Remove" variant="danger" size="xs"
              onClick={(e) => this.removeUser(user.id)}>
            <FontAwesomeIcon icon={faTrashAlt} title="Remove" />
          </Button>
        </td>
      </tr>
    ));
  }
  
  async addUser() {
    this.setState({ error: null, success: null });
    try {
      await server.addUnitUser(this.state.unit.id,
        this.state.selectedUserId, this.state.role);
      const unit = await server.getUnit(this.state.unit.id);
      this.setState({ unit });
      this.updateUsersToAdd();
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  
  unitForm() {
    return (
      <Form onSubmit={e => { e.preventDefault(); this.saveUnit(); } }>
        <fieldset>
          <legend>Unit</legend>
          <Form.Group as={Row} controlId="name">
            <Col sm="auto">
              <Form.Label>Unit Name</Form.Label>
              <Form.Control name="name" type="text" maxLength="50"
                value={this.state.unit.name}
                required onChange={e => this.handleUnitChange(e)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="active">
            <div className="col-md-2">
              <Form.Check name="active" type="checkbox" custom
          value={this.state.unit.active}
          onChange={e => this.handleUnitChange(e)}
          checked={this.state.unit.active}
          label="Active"/>
            </div>
          </Form.Group>
          <Form.Group>
            <Button variant="primary" type="submit">
          Save
            </Button>
            {this.state.loaded &&
        <Button title="Delete" variant="danger" size="" onClick={(e) => this.removeUnit()}>Delete Unit</Button>
            }
          </Form.Group>
        </fieldset>
      </Form>
    );
  }
  
  users() {
    const userList = this.unitUserList();
    if (!userList)
      return null;
    return (
      <section className="border">
        <h2>Users</h2>
        <div className="table-responsive">
          <Table bordered size="sm" className="data table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th>MSU ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {userList}
            </tbody>
          </Table>
        </div>
        {this.state.roles && this.state.usersToAdd && this.state.usersToAdd.length > 0 &&
          <Form onSubmit={e => { e.preventDefault(); this.addUser(); } }>
            <fieldset>
              <legend>Add a user</legend>
              <Form.Group as={Row} controlId="selectedUserId">
                <div className="col-md-2">
                  <Form.Label className="mr-1">Add a user:</Form.Label>
                  <Form.Control name="selectedUserId" as="select" custom
                  value={this.state.selectedUserId}
                  onChange={e => this.handleChange(e)} className="mr-1">
                    {this.state.usersToAdd.map((u) =>
                      <option key={u.id} value={u.id}>{u.msuId}</option>
                    )}
                  </Form.Control>
                </div>
              </Form.Group>
              <Form.Group as={Row} controlId="role">
                <div className="col-md-2">
                  <Form.Label>Role:</Form.Label>
                  <Form.Control name="role" as="select" custom value={this.state.role}
                  onChange={e => this.handleChange(e)}>
                    {this.state.roles.map((r, i) =>
                      <option key={i} value={r}>{r}</option>
                    )}
                  </Form.Control>
                </div>
              </Form.Group>
              <Form.Group>
                <Button variant="primary" type="submit">
              Add
                </Button>
              </Form.Group>
            </fieldset>
          </Form>
        }
      </section>
    );
  }
  
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <UnitNav/>
        <h1>{this.state.unit.id ? "Edit Unit: " + this.state.unit.name : "New Unit"}</h1>
        <ErrorAlert message={this.state.error}
          onClose={() => this.setState({ error: null })}/>
        <SuccessAlert message={this.state.success}
          onClose={() => this.setState({ success: null })}/>

        {(this.state.unit.id == null || this.state.loaded) &&
          this.unitForm()
        }
        {this.state.loaded &&
          this.users()
        }
      </>
    );
  }
  
}

Unit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string, // this initial unit id can be undefined for a new unit
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  // from redux connect:
  currentUnit: PropTypes.object,
  users: PropTypes.array,
  setCurrentUnit: PropTypes.func.isRequired,
  setUnits: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUnit } = state.app;
  const { users } = state.users;
  return { currentUnit, users };
};
const mapDispatchToProps = { setCurrentUnit, setUnits, fetchUsers };

export default connect(mapStateToProps, mapDispatchToProps)(Unit);
