import React, { Component } from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinkContainer } from 'react-router-bootstrap';

import { faArchive, faEnvelope, faPencilAlt, faTrashAlt } from
  '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UnitNav from './UnitNav';


class Help extends Component {
  
  componentDidMount() {
    document.title = "Override Requests - Help";
  }
  
  breadcrumbs() {
    return (
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Help</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  
  render() {
    return (
      <>
        {this.breadcrumbs()}
        <UnitNav/>
        <h1>Help</h1>
        <section className="border">
          <h2>Managing Semesters</h2>
          <p>Each unit can manage a unique list of semesters, which can be selected in the request form and on the home page to display a list of matching requests. Requests cannot be submitted if no semester has been created and enabled.</p>
          <p>Semesters can only be modified by unit administrators. The <em>Semesters</em> menu appears on their home page after <em>Unit Settings</em>.</p>
          <p>Each semester has a title and 2 settings:</p>
          <ul>
            <li><em>Active</em>: the semester is in the list of semesters that administrators use to select requests on the home page.</li>
            <li><em>Current</em>: the semester can be selected in the request form.</li>
          </ul>
          <p>Generally a new semester should be both "Active" and "Current", then later "Current" can be switched off when students should no longer be able to use it but administrators are still processing requests, and later "Active" can be switched off when all requests have been processed.</p>
        </section>
        <section className="border">
          <h2>Processing Requests</h2>
          <ol>
            <li>The request list is comprised of recent override request, organized by default with the oldest on top. Clicking on column names sorts the table. Clicking again on a column reverses the sort order, and clicking another time stops the sorting for that column. Two columns can be used for sorting, the first selected one being used as the primary. Yellow rows indicate un-touched requests. Requests can be processed with the pencil icon (<FontAwesomeIcon icon={faPencilAlt} title="pencil icon" />) on the right, responded to with the envelope (<FontAwesomeIcon icon={faEnvelope} title="envelope icon" />), permanently deleted with the trash icon (<FontAwesomeIcon icon={faTrashAlt} title="trash icon" />) and upon completion archived with the box icon (<FontAwesomeIcon icon={faArchive} title="archive icon" />). For now, click the pencil in the last column.</li>
            <li>You are now in the override request form that the student filled out, with some additional sections for administrators on top. Here is a list of all sections:
              <dl>
                <dt>Messages</dt>
                <dd>List of all messages (emails) related to the request that were sent.</dd>
                <dt>Updates</dt>
                <dd>List of all the updates administrators have done on the request.</dd>
                <dt>Request Processing</dt>
                <dd>Section to process the request, such as changing its status.</dd>
                <dt>Request For</dt>
                <dd>Basics of the request, as filled by the student.</dd>
                <dt>Administrative Information</dt>
                <dd>Administrative information, as filled by the student.</dd>
                <dt>Request Details</dt>
                <dd>More fields filled by the student.</dd>
              </dl>
          The save button at the end sends all changes made in the last 4 sections.
          Review the request and update the status. When finished, click Save.</li>
            <li>After updating the request, you will get a success message on top of the page. Look at the first section to manage messages.</li>
            <li>Click on "Send a new email" to email the student your decision. Whatever status the request was changed to on edit screen will determine what pre-canned email you will start with. You get a chance to adjust wording before sending (if granted, you still must enter the override in SIS the normal way you have always done it). Make sure to read through the email before sending. Several generated texts have writing prompts, such as the denial message, which indicates that a reason should be given with a [REASON] prompt. Click on Send when ready to send your email to the student.</li>
            <li>According to unit settings, decision emails will be sent to the student and may be CC'd or BCC'd to other email addresses as well. After submitting the email, you will get a success message on top. Click on "Request" in the breadcrumbs to go back to the request, or "Requests" to see the request list.</li>
            <li>From the request list (also visible on the home page), the student's request can be archived with the yellow button in the last column. This moves the request to the archive list which puts it in the queue to be purged after a 12 month storage period. Click the yellow archive button to complete the process.</li>
          </ol>
        </section>
        <section className="border">
          <h2>Unit URL for students</h2>
          <p>Students can be given a link for override requests that includes a pre-selection of the unit, for instance "<tt>https://overrides.natsci.msu.edu/unit-selection/1</tt>". The "<tt>1</tt>" at the end is the id for the unit, which can be retrieved by looking at the URL when editing the unit settings (in this case it would be "<tt>https://overrides.natsci.msu.edu/units/1/settings</tt>").</p>
        </section>
        <section className="border">
          <h2>Restricted data</h2>
          <p>Students may choose to restrict the release of some of their personal information. If a particular field has restricted data, the auto-filled value will be the netid instead of the real value. Students should still be able to modify the value before submitting the form.</p>
        </section>
      </>
    );
  }
  
}

export default Help;
